.Root {
  width: 100%;
  min-height: 40px;
  padding: 10px 20px;
  background-color: #FCF2E8;
  display: flex;
  row-gap: 6px;
  column-gap: 12px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.TextPrimary {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: var(--color-gray-900);
}

.TextSecondary {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: var(--color-gray-700);
}

.ActionButton {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--color-purple-600-primary);
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}
