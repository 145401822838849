.Scroll {
    height: 100%;
    overflow-y: auto;
    padding: 30px 20px;
}
.Root {

    display: flex;
    justify-content: center;
    align-items: center;
}

.EmptyState {
    font-size: 20px;
    font-weight: 400;
    line-height: 46px;
    letter-spacing: 0em;
    text-align: center;

    height: 720px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--color-gray-600)
}