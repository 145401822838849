.Root {
  width: 360px;
  border-radius: 25px;
  overflow: hidden;
  position: relative;
}

div.IconContainer {
  background: linear-gradient(166deg, #06162d 0%, #4b546d 100%);
}

.DialogRoot > span svg {
  color: var(--secondary-color);
}

.DialogRoot > span > button:hover,
.DialogRoot > span > button:active {
  background: transparent;
  color: green;
}

.IconContainer > div > span {
  font-size: 50px;
}

div.Container {
  background: var(--secondary-color);
  padding: 40px 30px 40px;
}

.Title {
  color: var(--color-gray-900);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

div.Content {
  margin: 0;
  text-align: center;
}

div.Actions {
  margin: 30px 0 0;
}

.Message {
  padding-top: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: var(--color-gray-900);
}

span.Icon {
  font-size: 120px;
  margin-top: -40px;
  margin-bottom: 20px;
}

button.CloseButton {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
  font-size: 20px;
}

.Overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

span.Spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #fff;
  font-size: 30px;
  color: var(--color-gray-900);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.12);
}