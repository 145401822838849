.Button {
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: white;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  height: 38px;
  background-color: var(--color-purple-700);
  border-radius: 16px;
}
.Button:hover:not([disabled]) {
  background-color: var(--color-purple-600-primary);
}
.Button:active:not([disabled]) {
  background-color: var(--color-purple-550);
}
.Button:disabled {
  color: rgb(255,255,255, .5);
  cursor: default;
}
.Button:disabled svg path {
  fill: rgb(255,255,255, .5);
}
