.Root {
  font-size: 16px;
  font-weight: 500;
  
  cursor: pointer;

  color: var(--color-gray-400)
}

.Primary {
  color: var(--color-purple-700);
}
.Primary:hover {
  color: var(--color-purple-800);
}
.Primary:active {
  color: var(--color-purple-900);
}
.Primary:disabled {
  color: var(--color-gray-400)
}

.Root:disabled {
  cursor: default;
}