.Root {
    width: 100%;
}

.Title {
    color: var(--color-gray-900);
    font-size: 30px;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0em;

    margin-top: 12px;
    margin-bottom: 20px;
}

.Text {
    color: var(--color-gray-900);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

@media only screen and (max-width: 680px) { 
    .Title {
        font-size: 24px;
    }
}