.Line {
  background: var(--color-gray-250);
  height: 1px;
  width: 100%;
}

.KingBadge {
  font-size: 22px;
}

.RadioInput {
  width: calc(100% - 34px);
}

.RadioInputWrapper span:hover,
.RadioInputWrapper input:hover {
  cursor: pointer;
}

.ConnectButton {
  background: var(--color-green-100);
  height: 54px;
  font-weight: 500;
  font-size: 18px;
  width: 100%;
  border-radius: 22px;
  padding: 13px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--color-gray-900);
  transition: all 0.3s ease;

  > svg {
    height: 20px;
    width: 14px;
  }

  &:hover {
    background: var(--color-green-300);
  }

  &:focus {
    background: var(--color-green-400);
  }
}

.ConnectedGoogleSheet {
  border-radius: 14px;
  background: var(--color-gray-200);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 74px;
  width: 100%;
  gap: 10px;
  padding: 13px 15px;
}

.IconWrapper {
  height: 48px;
  width: 48px;
  border-radius: 10px;
  background: var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    width: 21px;
    height: 30px;
  }
}

.ButtonTitle {
  color: var(--color-gray-600);
  font-size: 14px;
  margin-bottom: 3px;
}

.ButtonSubTitle {
  color: var(--color-green-900);
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

.LightSubTitle {
  color: var(--color-gray-500);
}

.OnlyOwn {
  font-size: 14px;
  line-height: 18px;
  color: #222222;
  background-color: var(--color-purple-100);
  padding: 15px;
  border-radius: 14px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
}

span.Icon {
  vertical-align: center;
  color: #5DABFF;
  margin-right: 8px;
  margin-top: -1px;
}

div.Indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;

  & span {
    margin-right: 10px;
  }
}