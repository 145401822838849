.ParentContainer {
  width: 304px;
  position: absolute;
  top: 42px;
  right: 0;
  margin-top: 12px;
  color: var(--secondary-color);
  background-color: var(--color-purple-1000);
  padding: 15px;
  text-align: left;
  box-shadow: 0 2px 10px 0 #0000004d;
  z-index: 11;
  border-radius: 13px;
}

.ParentContainer::before {
  content: '';
  position: absolute;
  bottom: 100%;
  right: 46px;
  border-width: 12px;
  border-style: solid;
  border-color: transparent transparent var(--color-purple-1000) transparent;
  filter: drop-shadow(0 2px 10px #0000004d);
}

.IconsContainer {
  display: flex;
  justify-content: space-between;
}

.CloseIcon {
  cursor: pointer;
}

.Title {
  font-size: 18px;
  font-weight: 500;
  margin: 14px 0 10px;
}

.Text {
  font-size: 14px;
  line-height: 20px;
  padding-right: 6px;
}
