.Root {
  height: 80px;
  display: grid;
  grid-template-columns: 80px 1fr 90px;
  background-color: #F8F8F8;
  border-radius: 19px;
}

.TextWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  overflow-wrap: anywhere;
  -webkit-box-orient: vertical;
}
.TextWrapper .Text,
.TextWrapper .HighlightText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.TextWrapper .Text {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: var(--color-gray-700)
}

.TextWrapper .HighlightText {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--color-gray-900)
}

.NumberWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.NumberWrapper .Number {
  background: #2837A6;
  height: 34px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 19px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.InteractionsText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap; 
}

.CoverWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}