.ProductRoot {
    margin-top: 10px;
    margin-bottom: 10px;
}

div.ProductRoot {
    padding-top: 15px;
    padding-bottom: 15px;
}

[class*="is--dragging"] {
    background-color: rgba(239, 239, 239, 0.8);
    box-shadow: 0 5px 15px 0 rgba(0,0,0, 0.1)
}