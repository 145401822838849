@keyframes fadeInOut {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
}

.Root {
    height: 27px;
    width: 100px;
}

.AnimationEffect svg {
    animation: fadeInOut 2s ease-in-out infinite;
    margin-left: -4px;
}

.DirectionBottom svg:first-of-type,
.DirectionLeft svg:first-of-type {
    animation-delay: 1.6s;
}

.DirectionBottom svg:nth-child(2n),
.DirectionLeft svg:nth-child(2n) {
    animation-delay: 1.2s;
}

.DirectionBottom svg:nth-child(3n),
.DirectionLeft svg:nth-child(3n) {
    animation-delay: 0.8s;
}

.DirectionBottom svg:nth-child(4n),
.DirectionLeft svg:nth-child(4n) {
    animation-delay: 0.4s;
}

.DirectionBottom svg:nth-child(5n),
.DirectionLeft svg:nth-child(5n) {
    animation-delay: 0s;
}

.DirectionBottom,
.DirectionTop {
    transform: rotate(-90deg);
}
.DirectionTop svg:first-of-type,
.DirectionRight svg:first-of-type {
    animation-delay: 0s;
}

.DirectionTop svg:nth-child(2n),
.DirectionRight svg:nth-child(2n) {
    animation-delay: 0.4s;
}

.DirectionTop svg:nth-child(3n),
.DirectionRight svg:nth-child(3n) {
    animation-delay: 0.8s;
}

.DirectionTop svg:nth-child(4n),
.DirectionRight svg:nth-child(4n) {
    animation-delay: 1.2s;
}

.DirectionTop svg:nth-child(5n),
.DirectionRight svg:nth-child(5n) {
    animation-delay: 1.4s;
}