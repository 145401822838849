
.Root {
    height: 100%;
    position: relative;
    border-radius: 22px;
    transition: background-color .25s ease-in-out;
    cursor: default;
    min-height: 60px;
}

/* WithMargin */
.WithMargin {
    padding: 10px;
    background-color: var(--color-gray-200);
}

.Disabled {
    opacity: .4;
}

/* Node Selection Radio Button */
.Radio {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 3;

    display: none;
}

.Root.ShowRadioBtnOnHover .Radio {
    display: none;
}

.Root.ShowRadioBtnOnHover:Hover .Radio,
.Root.ShowRadioBtnOnHover.Selected .Radio {
    display: block;
}

.Root:Hover .Radio,
.Root.Selected .Radio,
.Root .Radio {
    display: block;
}

.Root:hover .RadioUnselected {
    display: none;
}

.Root:hover .RadioUnselectedHover {
    display: block;
}


.Root.ShowRadioBtnOnHover:hover .RadioUnselected,
.Root.ShowRadioBtnOnHover .Radio:hover .RadioUnselectedHover {
    display: block;
}

.Root.ShowRadioBtnOnHover:hover .RadioUnselectedHover,
.Root.ShowRadioBtnOnHover .Radio:hover .RadioUnselected {
    display: none;
}

/* Overlay */

.Overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;

    transition: background-color .25s ease-in-out;
    opacity: 0.15;

    border-radius: 19px;
    z-index: 3;

    cursor: pointer;
}

.Disabled .Overlay {
    cursor: default;
}

.WithOverlay.WithDarkOverlay .Overlay {
    opacity: 0.2;
}

.Selected.WithMargin,
.Selected:not(.WithMargin) {
    --node-title-opacity: .7;
    --node-background: white;
}

.WithOverlay:hover .Overlay {
    background-color: #000;
}

.WithOverlay:hover:not(.Disabled) .Overlay {
    background-color: #000;
}

.WithOverlay.Selected .Overlay,
.WithOverlay.Selected .Overlay:hover {
    background-color: var(--color-purple-600-primary);
}

.WithOverlay.Selected:not(.Disabled) .Overlay,
.WithOverlay.Selected:not(.Disabled) .Overlay:hover {
    background-color: var(--color-purple-600-primary);
}