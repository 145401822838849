.Wrapper {
  height: 68px;
}

@media only screen and (max-width: 680px) {
  .Wrapper {
    height: auto;
    margin-bottom: 25px;
    padding-top: 0;
  }
}
