@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.Root {
  padding: 20px;
  min-width: 785px;
  background-color: #f8f8f8;
  animation: FadeIn 0.25s ease-in-out;
}
.Responsive {
  contain: layout inline-size;
  container-type: inline-size;

}
.Grid {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
}

.Filter {
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  grid-gap: 18px;
  min-width: 150px;
}

.LeftColumn,
.RightColumn {
  flex: 1 auto;
}

@container (min-width: 979px) {
  .Grid {
    flex-direction: row;
  }
  .LeftColumn {
    width: calc(60% - 15px);
  }
  .RightColumn {
    width: calc(40% - 15px);
  }
}
