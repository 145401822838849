.Header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  margin: 0 auto;
}

.Mode {
  color: var(--color-gray-900);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.Title {
  color: var(--color-gray-900);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

