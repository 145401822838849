.Root {
    background: #fff;
    border-radius: 25px;
    min-width: 630px;
    display: flex;
    flex-direction: column;
    padding: 10px 10px 15px;
    position: relative;
}
.Root.ResponsiveHeight {
    height: 100vh;
}

.Header,
.Footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ScrollerContent {
    height: 100%;
    padding: 10px 20px 0;
}

.Footer.Shadow {
    border-top-color: #EFEFEF;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.04);
}

.Footer {
    margin: 0 -10px -15px;
    padding: 15px 15px;
    position: relative;
    z-index: 1;
    border-top: 1px solid transparent;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.HeaderWithTitle {
    justify-content: space-between;
    padding-bottom: 10px;
}
.ModalTitle {
    color: var(--color-gray-900);
    font-size: 18px;
    font-weight: 500;
    padding-left: 10px;

    opacity: 0;
    transition: all .4s;
}
.Title {
    color: var(--color-gray-900);
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 30px;

    opacity: 0;
    transition: all .4s;
    padding-left: 10px;
}
.Visible {
    opacity: 1;
}
.VerticalScrollWrapper {
    padding: 0;
    margin: 0 -10px;
}
.VerticalScrollWrapper.FixedHeight {
    overflow: hidden;
    height: 100%;
}

.Root.ResponsiveHeight .VerticalScrollWrapper:not(.FixedHeight) {
    height: calc(100% - 33px);
}
.Root.ResponsiveHeight.WithFooterControls .VerticalScrollWrapper:not(.FixedHeight) {
    height: calc(100% - 48px - 54px);
}

.VerticalScroll {
    padding: 0 20px 20px 20px;
}

.WithBorders {
    border-top: 1px solid;
    border-color: #EFEFEF;
}

.Root.WithFooterControls .WithBorders {
    border-bottom: 1px solid #EFEFEF;
}

.Root:not(.WithFooterControls) .WithBorders {
    border-bottom: none;
}

@media (min-height: 550px) and (max-height: 650px)  {
    .Root.ResponsiveHeight {
        height: 100vh;
        max-height: 550px;
    }
}

@media (min-height: 651px) {
    .Root.ResponsiveHeight {
        height: 620px;
    }
}