.Root {
  padding: 10px 12px;
  border-radius: 16px;
  color: var(--color-gray-900);

  font-size: 14px;
  line-height: 18px;
}

.Active {
  background: var(--color-purple-200);
}
.Root:hover {
  background: var(--color-purple-100);
}