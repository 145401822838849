.Root {
    width: 610px;
    padding: 0px 10px;
}

.Label {
    font-size: 14px;
    font-weight: 400;
    color: var(--color-gray-500);

    padding-top: 13px;
    width: 55px;
}

.Line {
    background: var(--color-gray-300);
    height: 1px;
    width: 100%;
}

.TitleWrapper {
    width: 100%;
}

.TitleWrapper .Title {
    font-size: 24px;
    font-weight: 500;
    color: var(--color-gray-900);
    padding-left: 0px;
    padding-right: 0px;
}