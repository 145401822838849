
.CardWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    margin: 20px 0;
}

.BillingInterval {
    font-size: 14px;
}