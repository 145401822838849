
.Root {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
} 

.Content {
    width: 100%;
}
.Height {
    height: calc(100% - 107px);
    padding-top: 58px;
}
.HeightReadOnly {
    height: calc(100% - 67px);
    padding-top: 58px;
}

.FullHeight {
    height: calc(100% - 80px);
    padding-top: 76px;
}


.Fixed {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 4;
}