.Root {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 20px;
}

.Inline {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.Button {
    color: #222222;
    margin-right: 10px;
}