.Root {
  position: relative;
  height: calc(100vh);
}

.Scroll {
  height: 100%;
  overflow-y: overlay;
  overflow-x: overlay;
}
.Scroll::-webkit-scrollbar {
  width: 20px;
}
.Scroll::-webkit-scrollbar-track {
  background: transparent;
}
.Scroll::-webkit-scrollbar-thumb {
  background-color: rgba(217,217,217, .6);
  border-radius: 30px;
  background-clip: padding-box;
  border: 5px solid rgba(0, 0, 0, 0);
}

.Content {
  height: 100%;
  display: flex;
  
  flex-direction: column;
}


.FirstItem {
  margin-top: auto;
  min-height: 68px;
}
.LastItem {
  margin-bottom: auto;
  min-height: 60px;
}

.WithoutScroll {
  height: calc(100% - 120px);
}