.Root {
    color: var(--color-gray-900);
    background: var(--color-gray-300);
    font-size: 16px;
    line-height: 54px;
    font-weight: 500;
    border-radius: 10px;
    text-align: center;

    height: 54px;
    min-width: 135px;
    max-width: 100%;

    padding: 0 25px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.InlineBlock {
    display: inline-block;
    width: 100%;
}

.Flex {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.Title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.Icon {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Icon path {
    fill: var(--color-gray-900)
}