.Root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: inherit;
}

.Primary {
  color: var(--color-purple-600-primary);
}

.White {
  color: var(--secondary-color);
}

.Gray {
  color: var(--color-gray-800);
}