.Field {
  margin-top: 20px;
  margin-bottom: 10px;
  --tooltip-text-color: white;
  --tooltip-background-color: black;
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
}
.Label {
  display: block;
  text-align: left;

  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 8px;
}

.InputText {
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.15px;
  border: none;
  border-bottom: 1px solid var(--color-purple-600-primary);
  width: 100%;
  padding-bottom: 8px;

  &:active, &:focus {
    border-bottom: 2px solid var(--color-purple-600-primary);
  }
}

.Footer {
  display: flex;
  justify-content: flex-end;
}

.Cover {
  width: 100%;
  object-fit: cover;
  margin-top: 5px;
}

.Wrapper {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid var(--color-purple-600-primary);
  padding-bottom: 4px;
  cursor: text;
}
.ViewUrl {
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 0.15px;
  color: var(--color-purple-600-primary);

  max-width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  padding: 1px 2px;
}

.ErrorMessage {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.15px;
  color: #E54432;
  text-align: left;
  margin-top: 5px;
}

.InputLink {
  display: flex;
}

.Favicon {
  width: 32px;
  height: 32px;
  margin-right: 12px;
}

.TextLinkWrapper {
  width: 100%;
}
.TextLinkWrapperStep2 {
  width: calc(100% - 44px);
}


.TextLinkEditing {
  cursor: auto;
}
.TextLink,
.TextLinkEditing {
  color: #58595a;
  text-align: left;
  width: 100%;
  border-bottom: 1px solid var(--color-purple-600-primary);
  padding-bottom: 10px;
  background-color: transparent;
  outline: 0px solid transparent;
  display: inline-block;

  &:active, &:focus {
    border-bottom: 2px solid var(--color-purple-600-primary);
  }
}

.Overflow {
  color: transparent;
  cursor: auto;
}

.Textarea,
.TextareaEditing {
  max-height: 100px;
  font-size: 15px;
  width: 100%;
  border-bottom: 1px solid var(--color-purple-600-primary);
  padding: 5px 0;
  word-break: break-all;
  overflow-y: auto;
  border-radius: 0px;

  &:active, &:focus {
    border-bottom: 2px solid var(--color-purple-600-primary);
  }
}
.Textarea {
  color: var(--color-purple-600-primary);
  cursor: pointer;
}
.TextareaEditing {
  color: #58595a;
}

.TextareaLink {
  max-height: 100px;
  resize: none;
  box-sizing: border-box;
  font-size: 15px;
  width: 100%;
  border: none;
  outline: 0px solid transparent;
  color: var(--color-purple-600-primary);
  text-align: left;
}


/* Wrapping */
.TooltipWrapper {
  width: 100%;
  display: inline-block;
  position: relative;
  text-align: left;
}
/* Absolute positioning */
.TooltipTip {
  position: absolute;
  border-radius: 4px;
  transform: translate(-50%, 0px);

  padding: 6px;
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
}

/* CSS border triangles */
.TooltipTip::before {
  content: " ";
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

.Underline {
  border-bottom: 1px solid var(--color-purple-600-primary);
}