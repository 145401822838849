@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Root {
  margin: 20px;
  width: calc(100% - 40px);
  min-width: 320px;
  height: auto;
  min-height: 0;
  padding-top: 100px;

  box-sizing: border-box;
  border-radius: 25px;
  display: flex;

  align-items: center;
  justify-content: center;

  overflow-y: auto;

  animation: FadeIn 0.25s ease-in-out;
}

.Root.Center {
  height: calc(100% - 40px);
  min-height: 380px;
  padding-top: 0px;
}

.Centred {
  max-width: 325px;
  text-align: center;
}

.Centred h3 {
  font-weight: 800;
  font-size: 24px;
  line-height: 37px;
  color: var(--color-gray-600);
  margin: 15px 0 0;
}

.Centred p {
  color: var(--color-gray-600);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin: 10px 0 0;
}


.PagesBoard svg {
  height: 135px;
  width: 110px;
}

