.Root {
  background: white;
  height: 100%;
  width: 100%;
  padding: 30px 0;
  position: relative;
}

.Header {
  position: absolute;
  right: 10px;
  top: 10px;
}

.SpinnerBackground {
  position: absolute;
  background: white;
  height: 100%;
  width: 100%;
  z-index: 101;
  opacity: 0.8;
}

.Title {
  color: var(--color-gray-900);
  text-align: center;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.SubTitle {
  color: var(--color-gray-500);
  text-align: center;

  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  margin-top: 10px;
}

.Menu {
  display: flex;
  justify-content: center;
  column-gap: 5px;
  row-gap: 5px;

  margin-top: 30px;
  flex-wrap: wrap;
}

.CarouselWrapper {
  margin: 30px 0;
  position: relative;
}

.BottomDescriptionText {
  color: var(--color-gray-500);
  text-align: center;

  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.Root .Spinner {
  z-index: 105;
}