.Root {
  height: 100%;

  display: flex;
}

.Menu {
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  padding: 20px 0 20px 20px;
  width: 240px;
  overflow-y: auto;
  min-height: 100%;
}

.Content {
  flex: 1 1;
}

.ContentScroll {
  width: 100%;
  display: flex;
  justify-content: center;

  padding-bottom: 50px;
}

.Arrow {
  position: absolute;
  left: 235px;
  margin-top: 12px;
  margin-left: 16px;
}

.IconWrapper path {
  fill: var(--color-purple-700);
}