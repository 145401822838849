.Placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 15px;
}

.PlaceholderMessage {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: var(--color-gray-400);

  padding: 0 20px;
}

.Image {
  cursor: pointer;
  border-radius: 13px;

  box-sizing: border-box;
  box-shadow: -55px 60px 23px 0px rgba(0, 0, 0, 0.00), -35px 39px 21px 0px rgba(0, 0, 0, 0.01), -20px 22px 18px 0px rgba(0, 0, 0, 0.05), -9px 10px 13px 0px rgba(0, 0, 0, 0.09), -2px 2px 7px 0px rgba(0, 0, 0, 0.10);
}

.Image:hover {
  border: solid 2px var(--color-purple-400);
}

.Image:active {
  border: solid 3px var(--color-purple-500);
}