.Root {
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 14px;
}

.Switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 14px;
  margin-right: 15px;
}

.Switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.Slider {
  position: absolute;
  cursor: pointer;
  top: 2px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #E5E5E5;
  -webkit-transition: .4s;
  transition: .4s;
  height: 10px;
}

.Slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 18px;
  left: 0px;
  bottom: 4px;
  background-color: #C4C4C4;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  -webkit-transition: .4s;
  transition: .4s;
  top: -2px;
}
/*
input:checked + .Slider {
  background-color: #2196F3;
}

input:focus + .Slider {
  box-shadow: 0 0 1px #2196F3;
}*/

input:checked + .Slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
  background-color: var(--color-purple-700);
}

/* Rounded sliders */
.Slider.Round {
  border-radius: 30px;
}

.Slider.Round:before {
  border-radius: 30px;
}