.Title {
  font-weight: 500;
  font-size: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.EmailPicker {
  display: grid;
  grid-template-columns: 1fr 15fr 4fr;
  margin-top: 35px;
  margin-bottom: 30px;
}

.AutocompleteWrapper {
  padding: 0 10px;
}


