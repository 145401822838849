ul.Menu {
  min-width: auto;

  & span > a {
    display: inline-flex;
    align-items: center;
  }
}

.VideoUsageContainer {
  margin: 10px 10px 0;
}

.Divider {
  border-bottom: solid 1px var(--color-gray-200);
  margin: 0;
}

.ProfileNameDialog {
  width: 280px;
  padding: 0 5px;
  margin-bottom: -5px;
}

.ProfileNameDialogTitle {
  font-size: 24px;
  text-align: center;
  font-weight: 500;
}

.ProfileNameInput {
  margin: 40px 0 25px;
}
