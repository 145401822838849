:root {
  --text-size: 18px;
}
body {
  --header-height: 60px;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:focus {
  outline: 0px solid transparent;
}