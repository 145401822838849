.Sidebar {
  display: flex;
  flex-basis: 240px;
  min-width: 212px;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  background: #ffffff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.Sidebar:hover .HiddenButton button {
  opacity: 1;
}

.HiddenButton button {
  opacity: 0;
}

.Header {
  margin: 20px 20px 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.Body {
  margin: 0;
  flex-grow: 4;
  overflow-y: auto;
}

.Footer {
  margin: 0px 0px 10px 0;
}
