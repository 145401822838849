
@media only screen and (min-width: 681px) {
  .Root {
    padding: 30px 20px 15px 20px;
  }    
}

@media only screen and (max-width: 680px) {
  .Root {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-image: url('../icons/MobileHeaderBackground.svg');
    background-size: cover;
  }
}