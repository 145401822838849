.InfoContainer {
  width: 450px;
}

.InfoRow {
  padding: 20px 0;
  border-bottom: solid 1px var(--color-gray-200);
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  &:last-of-type {
    border-bottom: none;
  }
}

.InfoRowName {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: var(--color-gray-500);
  padding-bottom: 5px;
}

.InfoRowNameValue {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: var(--color-gray-900);
}

.InfoRowTitleContainer {
  display: flex;
  flex-flow: column;
}

.ButtonText {
  min-width: 46px;
}
