.Root {
  background: white;
  border-radius: 25px;
  padding: 25px;
  width: 650px;

  margin: 0 auto;
  display: flex;
  flex-direction: row;

  padding: 35px 20px;
}

.Favicon {
  height: 32px;
  margin-right: 12px;
  width: 32px;
}

.Url {
  font-size: 15px;
  padding: 5px 0;
  line-height: 17px;
  color: var(--color-purple-600-primary);
  border-bottom: 1px solid var(--color-purple-600-primary);
  word-break: break-word;

  flex: auto;
  display: flex;
  align-items: center;

  &:active, &:focus {
    border-bottom: 2px solid var(--color-purple-600-primary);
  }
}