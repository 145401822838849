.Root {
  position: relative;
  margin: 15px auto 40px auto;

  width: calc(var(--node-width) * 2 + 16px);

  --node-background: var(--color-gray-200);
  --node-title-color: var(--color-gray-900);
  --node-title-fontsize: 16px;
  --node-title-lineheight: 18px;
  --node-title-opacity: 1;
  --node-text-color: var(--color-gray-700);
  --node-text-fontsize: 14px;
  --node-text-lineheight: 18px;
  --node-padding: 15px;
}

div.NodeDescription {
  align-items: center;
}
