.Root {
    position: relative;
    max-height: 60px;
}
.Image {
    max-height: 60px;
    max-width: 100%;

    border-radius: 10px;

    vertical-align: middle;
}

.LoadingWidth {
    width: 60px;
}