.Root {
  position: relative;
  width: 450px;
  height: 610px;
  background: linear-gradient(to bottom, #06162d 0%, #9496b1 100%);
  border-radius: 25px;
}

.HeaderCnt {
  padding: 40px 40px 0 40px;
}

.Header {
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  color: var(--secondary-color);
}

.HeaderBoldWord {
  font-weight: 500;
}

.HeaderDescription {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: var(--secondary-color);
  margin-top: 15px;
}

.DetailsCnt {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 407px;
  border-radius: 25px;
  padding: 24px 40px 35px 40px;
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Bullets {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.Bullet {
  display: flex;
  align-items: flex-start;
  padding: 12px 0;
}

.BulletTexts {
  margin-left: 15px;
}

.BulletTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
  text-align: left;
}

.BulletBody {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin-top: 2px;
}

.ActionCnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.Info {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: var(--color-gray-750);
}

.ActionButton {
  width: 200px;
}

button.CloseButton {
  font-size: 20px;
  position: absolute;
  top: 15px;
  right: 15px;
}