.SectionTitle {
    display: flex;
    gap: 5px;

    margin-bottom: 5px;

    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
}

.Responses {
    margin-bottom: 25px;
}

.OnlyOwn {
    font-size: 14px;
    line-height: 18px;
    color: #222222;
    background-color: var(--color-purple-100);
    padding: 15px;
    border-radius: 14px;
}

span.Icon {
    vertical-align: center;
    color: #5DABFF;
    margin-right: 8px;
    margin-top: -2px;
}