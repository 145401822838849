.Root {
    background: #FF9604;
    border-radius: 13px;

    font-weight: 500;
    font-size: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #FFFFFF;
}