.Layout {
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.WelcomeContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Title {
  font-size: 30px;
  font-weight: 500;
  color: var(--color-gray-900);
  margin-bottom: 30px;
  text-align: center;
}

.Button {
  align-self: center;
}

.SubTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: var(--color-gray-900);
  text-align: center;
  margin-bottom: 40px;
}

.WelcomeFooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 50px;
}

.FooterText {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 15px;
  text-align: center;
  color: var(--color-gray-900);
}

.FooterButton {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: var(--color-purple-700);
  background: transparent;
  text-align: center;
}

.FooterButton:not(:disabled):hover {
  background: transparent;
}

.FooterButton:not(:disabled):active {
  background: transparent;
}
