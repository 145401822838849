.Root {
    --emoji-picker-emoji-width: 32px;

    z-index: 1000;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.standaloneRoot {
    width: 16px;
}

.positionTop {
    top: -320px;
}

.Background {
    z-index: 1000;
    width: calc(var(--emoji-picker-emoji-width) * 14 + 16px);
    color: #222222;
    padding: 20px 0px 12px 14px;
    text-align: left;

    background: #FFFFFF;
    border-radius: 25px;
}

.standaloneBackground {
    position: absolute;
    border: 1px solid #F8F8F8;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.Left {
    left: -54px;
}

.Right {
    right: -15px;
}

.PickerEmojis {
    display: block;
    max-height: calc(var(--emoji-picker-emoji-width) * 7 - 8px);
    overflow-y: scroll;
    font-size: 23px;
}

.PickerEmojis::-webkit-scrollbar {
    width: 16px;
}

.PickerEmojis::-webkit-scrollbar-track {
    background: transparent;
}

.PickerEmojis::-webkit-scrollbar-thumb {
    background-color: #555555;
    border-radius: 8px;
    background-clip: padding-box;
    border: 6px solid rgba(0, 0, 0, 0);
}

.Reference {
    display: inline-block;
    overflow: hidden;
    font-size: 16px;
    color: transparent;
    user-select: none;
}

.Char {
    margin-left: -13px;
}

.Emoji {
    display: inline-block;
    font-size: 24px;
    min-width: calc(var(--emoji-picker-emoji-width) - 1px);
    height: calc(var(--emoji-picker-emoji-width) - 1px);
    padding-left: 1px;
    padding-top: 1px;
    text-align: center;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    background-color: rgba(255, 0, 0, 0.5);
    background-color: transparent;
    border-radius: 4px;
    overflow: hidden;
    padding: 3px 4px;
}

.Emoji:hover {
    background-color: rgba(255, 255, 255, 0.05);
}


.Groups {
    padding-left: 6px;
    padding-right: 10px;
    display: block;
    margin-bottom: 4px;
    display: flex;
}

.Group,
.GroupSelected {
    margin-right: 8px;
    margin-bottom: 12px;
    user-select: none;
    cursor: pointer;
    font-weight: normal;
    font-size: 15px;
    letter-spacing: 0.5px;
}

.Group:hover {
    color: var(--color-purple-700);
}

.GroupSelected {
    color: var(--color-purple-700);
}
