.Root {
    padding: 20px;
    background: var(--color-purple-600-primary);
    color: white;

    display: flex;
    flex-direction: column;
    row-gap: 15px;

    position: relative;
}

.Root .CloseIcon {
    position: absolute;
    top: 12px;
    right: 10px;

    cursor: pointer;
    opacity: 1;
}


.Title {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;

    padding-right: 20px;
}

.Text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.LearnMore {
    color: white;
    font-size: 16px
}
.LearnMore .Icon {
    vertical-align: middle;
}