.Root {
  padding: 0 0 25px;
}

.Modal {
  min-height: 570px;
  max-height: 800px;
}

.Content {
  padding: 10px 5px 0;
}

.HeaderRoot {
  width: 100%;
}

.Header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
}

.Title {
  margin: auto;
  padding-left: 38px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: var(--color-gray-900);
}

.Divider {
  height: 1px;
  width: 100%;
  background-color: var(--color-gray-200);
}

.Subject {
  margin: 15px 0;
}

.Message {
  margin-bottom: 20px;
}

.FooterRoot {
  width: 100%;
}

.Attachment {
  position: relative;
  margin-bottom: 20px;
}

.AttachmentListItem {
  cursor: default;
  border: 1px solid var(--color-gray-150);
}

button.AttachmentRemove {
  position: absolute;
  z-index: 10;
  top: 11px;
  left: 157px;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0.9;
}

button.AttachmentRemove:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
