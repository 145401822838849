.Root {
    width: 100%;
    max-width: 60%;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex: 1;
}

.Content {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 20px 70px 20px;
}

div.Indicator {
    margin-top: 7px;
    max-width: 167px;
    padding-top: 4px;
    padding-bottom: 4px;
}
div.Indicator.Limited {
    opacity: 0.85;
}

span.Icon {
    font-size: 120px;
    margin-bottom: 10px;
}

div.DeleteContentPopUp {
    margin-top: -30px;
}