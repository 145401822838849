.Root {
  padding-top: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@container (max-width: 979px) {
  .Root {
    margin: 0px 10px;
  }
}

.IconWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.TitleWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 40px;
  gap: 5px;
}

.CardHeight {
  height: 100%;
}

.Scrollable {
  margin-right: -10px;
  padding-right: 10px;
  overflow: auto;
  height: 860px;
}
.LoadingContent {
  min-height: calc(100% - 100px);
  position: relative;
  overflow: hidden;
}

.MoreThan50Message {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--color-gray-600);
  height: 52px;
}