.Root {
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;

    height: 24px;
    padding: 4px 8px;
    border-radius: 24px;
    white-space: nowrap;
}

.Blue {
    color: white;
    background: var(--color-purple-900);
}

.MarketingPurple {
    color: var(--color-gray-900);
    background: var(--color-brand-marketing-purple);
}