.Main {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 160px);
  padding: 0 1rem;
}

.Form {
  display: flex;
  flex-direction: column;
  width: 426px;
  margin-bottom: 2rem;
  padding-top: 1rem;
}

.EmailConsent {
  margin-bottom: 20px;

  & label {
    color: var(--color-gray-900);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-left: 0;
  }
}

.Disclaimer {
  margin-top: 40px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: var(--color-gray-600);
  text-align: center;

  & a {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: var(--color-purple-700);

    &:hover {
      color: var(--color-purple-800);
    }
  }
}

.EmailConsentInput {
  background-color: var(--color-purple-600-primary);
}

@media only screen and (max-width: 680px) {
  .Form {
    padding-top: 0;
  }
}
