.Root {
  position: absolute;
  top: 0px;
  width: calc(100% - 20px);
  height: 70px;
  padding: 15px 0px 15px 20px;
  background: linear-gradient(180deg, #171717, hsla(0, 0%, 9%, 0));
  z-index: 10;
}

.Inner {
  display: grid;
  grid-template-columns: 1fr auto;
}
