.Root {
    color: var(--color-gray-900);
    
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-top: 16px;
}

.SelectedLabel {
    font-size: 14px;
}