.Root {
    display: flex;
    align-items: center;
    justify-content: space-between;

    column-gap: 20px;

    height: 100%;
    padding: 0 20px;
}

.Title {
    display: flex;
    column-gap: 5px;
    align-items: center;
}

.Title h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;

    white-space: nowrap;
}

.PagesBoardKeyIcon{
    width: 20px;
    height: 20px;
}