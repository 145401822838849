.Root {
  position: relative;

  &.Disabled {
    & .Value,
    & .ArrowDownIcon path {
      color: #c4c4c4;
      stroke: #c4c4c4;
      cursor: default;
    }
  }
}

.Value {
  white-space: nowrap;
  font-size: 12px;
  padding: 5px 7px 7px 7px;
}

.ArrowDownIcon {
  margin-left: 6px;
}

.Options {
  position: absolute;
  right: -8px;
  top: -20px;
  background: white;
  border-radius: 13px;
  padding: 5px 0px;
  box-shadow: 0px 4px 20px rgba(74, 154, 242, 0.45);
  z-index: 1;
}

.Option {
  height: 30px;
  min-width: 120px;
  max-width: 200px;
  padding: 0px 15px;
  margin: 2px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.Option:hover {
  background: var(--color-purple-100);
}

.OptionText {
  display: inline-flex;
  gap: 5px;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
  font-size: 14px;
  margin-right: 10px;
}
