.Title {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  white-space: pre-line;
  word-break: break-word;
}

.Text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-line;
  word-break: break-word;
  position: relative;

}

.Title a,
.Text a {
  color: var(--color-purple-700);
}
