.Root {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.Icon {
  vertical-align: middle;
}

.Margin {
  margin-right: 15px;
  margin-top: -1px;
}

.Button {
  display: flex;
  align-items: center;
  justify-content: center;
}
