.Title {
  font-size: 30px;
  line-height: 35px;
  letter-spacing: 0.0025em;
  text-align: center;
  font-weight: 700;
  margin-bottom: 40px;
}

@media only screen and (max-width: 680px) {
  .Title {
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
  }
}
