.Root {
  margin-bottom: 20px;

}

.Input {
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.15px;
  border: none;
  width: 100%;
  text-overflow: ellipsis;
  padding-bottom: 8px;
}

.Underline {
  border-bottom: 1px solid var(--color-purple-600-primary);

  &:active, &:focus {
    border-bottom: 2px solid var(--color-purple-600-primary);
  }
}