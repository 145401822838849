.Root  {
  background-color: var(--node-background);
  border: solid 1px;
  border-color: var(--node-background);
  transition: border-color,background-color .25s ease-in-out;
  height: 100%;
  border-radius: 19px;
  overflow: hidden;
}

.Title {
  font-size: var(--node-title-fontsize);
  line-height: var(--node-title-lineheight);
  font-weight: 500;
  overflow-wrap: anywhere;
  color: var(--node-title-color);
  opacity: var(--node-title-opacity);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.Icon {
  display: block;
  width: 100%;
}

.Duration {
  color: var(--color-gray-700);
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 3px;
}