.Root {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
  color: rgb(18, 18, 18);
  text-transform: uppercase;
}

.Root.Disabled {
  border: 1px dashed #8B8B8B;
}

.CursorPointer {
  cursor: pointer;
}