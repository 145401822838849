div.Banner {
    padding: 18px 20px;
    justify-content: space-around;
    box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.04);
}

div.Banner a {
    white-space: nowrap;
    font-size: 18px;
    font-weight: 500;
    height: 46px;
    border-radius: 19px;
    width: 130px;
    padding: 4px 16px;
}

.Banner:not(.UnBranded):hover .Hover {
    opacity: 1;
}

.MessageWrap {
    display: flex;
    flex-direction: column;
}

.Message {
    font-size: 18px;
    line-height: 20px;
}

span.Logo {
    align-self: flex-start;
    color: var(--color-gray-white);
    display: block;
    height: 24px;
    margin-bottom: 5px;
}

svg.LogoIcon {
    display: block;
    height: 24px;
}

.Icon {
    color: var(--secondary-color);
    margin-left: 5px;
}

.Hover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: auto;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-purple-700);
    color: var(--secondary-color);
    opacity: 0;
    transition: opacity 0.2s ease;

    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
}

.KingIcon {
    margin-right: 10px;
}