.Divider {
  border: solid 2px white;
}

.ContentWrapper {
  height: calc(600px - 145px);
}

.TextInput {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 0 0 0;
  font-size: var(--text-size);
  font-family: inherit;
  resize: none;
  border: none;
  overflow: auto;
  outline: none;
  box-shadow: none;
}

.TextInput::placeholder {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.15000000596046448px;
}

.SmileIcon {
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: visibility, opacity;
  transition-delay: 0.25s;
  transition-duration: 0.1s;
  border-radius: 16px;
  padding: 8px;
  height: 38px;
  width: 38px;
}

.ShowPicker .SmileIcon,
.ShowPicker .SmileIcon,
.ContentWrapper:focus-within .SmileIcon {
  visibility: visible;
  opacity: 1;
}

.EmojiPickerWrapper {
  position: absolute;
  top: 9px;
  right: 54px;
  visibility: hidden;
  opacity: 0;
  transition: visibility, opacity;
  transition-delay: 0.25s;
  transition-duration: 0.1s;
}
@-moz-document url-prefix() {
  .EmojiPickerWrapper {
    top: -136px;
  }
}
.ShowPicker.EmojiPickerWrapper,
.ContentWrapper:focus-within .EmojiPickerWrapper {
  visibility: visible;
  opacity: 1;
}

.TextareaWrapper {
  height: 100%;
}

