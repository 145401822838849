.CreateBoardWrapper {
  padding: 0 15px;
}

.CreateBoardButton {
  margin-top: 5px;
  padding: 0 15px;
}

.BoardTitle {
  display: flex;
  align-items: center;
  margin: 30px 20px 10px;
  line-height: normal;

  color: var(--color-gray-900);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
