.Root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.Spacer {
  width: 38px;
}

.Title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: #222222;
}