.Root {
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.Limited {
  opacity: 0.5;
}

.Title {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
  word-break: break-all;

  color: var(--color-gray-900);
}

.Text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  color: var(--color-gray-900);

  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.WithOneLine {
  -webkit-line-clamp: 1;
  word-break: break-all;
}

.Icon,
.Icon>svg {
  width: 33px;
  color: #222;
}

span.FormIcon,
span.CheckoutIcon {
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  min-width: 38px;
  height: 46px;
  background: var(--color-gray-300);
}

span.CheckoutIcon svg { 
  color: var(--color-gray-900);
}

.Crown {
  display: flex;
  margin-right: -5px;
  margin-bottom: auto;
}

.CrownWithMargin {
  margin-top: -2px;
}