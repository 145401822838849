.Root {
  width: 340px;
  padding: 0 10px;
  margin-top: -4px;
}

.Title {
  font-size: 24px;
  text-align: center;
  font-weight: 500;
}

.Body {
  display: flex;
  align-items: center;
  padding: 40px 0 25px;

  button.Icon,
  button.Icon:disabled {
    font-size: 24px;
    border-radius: 12px;
    background: var(--color-gray-200);
    width: 46px;
    height: 46px;
    transition: unset;
    color: unset;

    &:hover {
      border: 2px solid var(--color-purple-400);
    }

    &:active {
      border: 2px solid var(--color-purple-600-primary);
    }
  }
}

.Input {
  margin-left: 10px;
  width: 100%;
}

div.Dialog {
  box-shadow: rgba(0, 0, 0, 0.15) 0 4px 16px;
  background: rgb(255, 255, 255);
  padding: 50px 30px 40px;
  overflow: visible;
}
