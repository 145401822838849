@import "./theme-fonts.css";

:root {
  --main-color: black;
  --secondary-color: #FFFFFF;
  --background-color: #E5E5E5;
  --text-color: #0062E0;
  --sidebar-background: #EFEFEF;
  --color-gray-900: #222222;
  --color-gray-800: #3D3D3D;
  --color-gray-750: #4D4D4D;
  --color-gray-700: #666666;
  --color-gray-600: #888888;
  --color-gray-500: #999999;
  --color-gray-400: #BCBCBC;
  --color-gray-300: #DBDBDB;
  --color-gray-250: #E8E8E8;
  --color-gray-200: #EFEFEF;
  --color-gray-150: #F5F5F5;
  --color-gray-100: #F8F8F8;

  --color-gray-white: #222222;

  --color-blue-1000: #002246;
  --color-blue-900: #00438B;
  --color-blue-800: #005DC2;
  --color-blue-700: #0070E8;
  --color-blue-600-primary: #007AFF;
  --color-blue-500: #5DABFF;
  --color-blue-400: #A2CFFF;
  --color-blue-300: #D1E7FF;
  --color-blue-200: #DEEEFF;
  --color-blue-150: #E9F4FF;
  --color-blue-100: #F0F7FF;

  --color-purple-1000: #000B46;
  --color-purple-950: #1F2A7F;
  --color-purple-900: #2837A6;
  --color-purple-800: #2C3DC2;
  --color-purple-700: #3A4DDF;
  --color-purple-600-primary: #4359FF;
  --color-purple-550: #697AFF;
  --color-purple-500: #8E9BFF;
  --color-purple-400: #B4BDFF;
  --color-purple-300: #D9DEFF;
  --color-purple-200: #E8EBFF;
  --color-purple-150: #EFF1FF;
  --color-purple-100: #F2F4FF;


  --color-green-100: #dff4ea;
  --color-green-300: #c2f0da;
  --color-green-400: #a7e2c6;
  --color-green-900: #149000;
  
  --color-orange-50: #FFF0DF;

  --color-brand-marketing-purple: #F0E1FF;
  --color-brand-marketing_purple_200: #C69DEF;
  --color-brand-marketing_purple_400: #613699;

  --color-others-alert: #E33922;
  --color-others-alert-100: #FFEBE9;
  --color-others-alert-600: #E33922;

  --node-width: 200px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

button {
  background-color: transparent;
  color: inherit;
  border-width: 0;
  padding: 0;
  cursor: pointer;
}

figure {
  margin: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
  margin: 0;
}

ul,
ol,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}

p {
  margin: 0;
}

cite {
  font-style: normal;
}

fieldset {
  border-width: 0;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Roboto', 'Open Sans', sans-serif;
}




.create-page-enter {
  padding-top: 125%;
  background: transparent;
}

.create-page-enter-active {
  padding-top: 0;
  transition: all 400ms linear;
}

.create-page-exit {
  background: #E5E5E5;
  padding-top: 0;
}

.create-page-exit-active {
  padding-top: 125%;
  transition: all 400ms linear;
  background: transparent;
}



.edit-new-page-exit {
  background: #E5E5E5;
  padding-top: 0;
}

.edit-new-page-exit-active {
  padding-top: 125%;
  transition: all 400ms linear;
  background: transparent;
}