.Root {
  min-height: 200px;
}

.Label {
  color: var(--color-gray-900);
  font-size: 16px;
  line-height: 33px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
}

.Content {
  position: relative;
}

@container (max-width: 979px) {
  .Root {
    margin: 0px 10px;
  }
}