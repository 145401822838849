.Button {
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: white;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  height: 38px;
  background-color: var(--color-purple-700);
  border-radius: 16px;
}
.Button:hover:not([disabled]) {
  background-color: var(--color-purple-500);
}
.Button:active:not([disabled]) {
  background-color: var(--color-purple-400);
}
.Button:disabled {
  color: rgb(255,255,255, .5);
  cursor: default;
}
.Button:disabled svg path {
  fill: rgb(255,255,255, .5);
}
.Button svg {
  margin-right: 10px;
}

.ButtonRound {
  width: 38px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}
.ButtonRound svg {
  margin-right: 0px;
}

.Actions {
  display: flex;
  gap: 10px;
}

.CloseOption {
  display: flex;
  align-items: center;
  color: white;
}

.ShareButton {
  gap: 6px;
}

.ShareButton svg {
  width: 18px;
  margin: 0;
}

.ShareIcon {
  vertical-align: middle;
}

.BtnText {
  font-size: 14px;
  margin-left: 10px;
}

.Loading {
  width: 38px;
}

.Hidden {
  display: none !important;
}
