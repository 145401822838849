.ScrollArea {
  height: 100%;
  padding: 30px 20px;
}

.Root {
  width: 70%;
  row-gap: 20px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1325px) {
  .Root {
    width: 530px;
  }
}

.FlexContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
}

.FlexItem {
  flex: 0 0 calc(33.333% - 6px);
  box-sizing: border-box;
}

.FlexItem::after {
  content: '';
  flex: 0 0 calc(33.333% - 6px);
  box-sizing: border-box;
}