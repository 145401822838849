.Root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Icon {
    display: flex;
}

.Title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    margin-bottom: 15px;

    color: #222222;
}

.Message {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;

    margin-bottom: 30px;

    color: #000000;
}

.Controls {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}