.Root {
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 5px;
}

.Margin {
    margin-top: 5px;
}

.ButtonMargin {
    word-break: keep-all;
    word-wrap:unset;
    white-space: nowrap;
    margin-left: -10px;
}