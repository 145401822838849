.Members,
.EmptyMembers {
  min-height: 430px;
}

.Title {
  font-weight: 500;
  font-size: 22px;
  line-height: 19px;
}

.SubTitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #919191;
  margin-top: 10px;
}

.MagnifyingGlassIcon {
  margin-left: 15px;
}
.SearchInput {
  border: none;
  width: 100%;
}

.MemberGroup {
  padding: 20px 10px 65px 10px;
  column-gap: 20px;
  row-gap: 8px;
  display: grid;
  grid-template-columns: 50%;
  max-height: 329px;
  grid-template-areas: 'item item';
}