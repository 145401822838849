.Root {
  height: 100%;
  width: 100%;
  opacity: 0;
  animation: 0s linear .2s forwards delayedShow;
  position: absolute;
  top: 0px;
  display: flex;
}

.JustifyFlexStart { justify-content: flex-start;}
.JustifyCenter { justify-content: center;}
.JustifyFlexEnd { justify-content: flex-end;}

.AlignFlexStart { align-items: flex-start;}
.AlignCenter { align-items: center;}
.AlignFlexEnd { align-items: flex-end;}

.SpinnerIcon {
  height: 81px;
  width: 81px;
}

.HideContent {
  opacity: 0;
}

.Content {
  opacity: 1;
  height: 100%;
  transition: opacity .1s;
}

@keyframes delayedShow {
  to { opacity: 1; }
}