.Root {
  background: var(--color-purple-100);
  height: 40px;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 16fr 4fr;
  margin-bottom: 25px;
}

.Root .Link {
  padding: 12px 20px;
  font-size: 14px;
  flex-grow: 52;
  transition: 0.4s;
}
.Root.Disabled .Link {
  color: #AAAAAA;
  user-select: none;
}
.Root.Disabled .BallPulse > div {
  background-color: #AAAAAA;
}

.Root .CopyLinkBtnWrapper {
  flex-grow: 3;
}


.Loading .BallPulse > div:nth-child(1) {
  animation: scale 1.5s -.5s infinite; 
}
.Loading .BallPulse > div:nth-child(2) {
  animation: scale 1.5s -0.25s infinite; 
}
.Loading .BallPulse > div:nth-child(3) {
  animation: scale 1.5s 0s infinite; 
}
.BallPulse > div {
  background-color: var(--color-purple-700);
  width: 2px;
  height: 2px;
  border-radius: 100%;
  margin: 0px 3px;
  display: inline-block; 
  animation-fill-mode: both;
}
@keyframes scale {
  30% {
    transform: scale(4);
    opacity: 1;
  }
  50% {
    transform: scale(2);
    opacity: .5;
  } 
  65% {
    transform: scale(1.5);
    opacity: .1; 
  } 
  85% {
    transform: scale(1);
    opacity: 0; 
  } 
}