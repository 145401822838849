@keyframes slideUp {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .Root {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  
    z-index: 99;
  
    animation: 0.3s ease-in-out slideUp;
  }
  
  .WithMargins {
    padding: 40px 30px;
    border-radius: 25px 25px 0px 0px;
    background: white;
  }
  
  .WithHigherZIndex {
    z-index: 100;
  }
  