@keyframes FadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.Root {
  margin: 20px auto 0 auto;
  width: calc(var(--node-width) * 2 + 16px);
  animation: FadeIn 0.25s ease-in-out;
}