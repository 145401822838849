.Root {
  max-width: 716px;
  width: 100%;
  padding: 20px 20px 0;

  display: flex;
  justify-content: center;
  overflow-y: auto;

  font-family: -apple-system, system-ui, BlinkMacSystemFont, Roboto;
}

@media screen and (max-width: 680px) {
  .Root {
    padding: 0;
  }
}
