.Divider {
  border: solid 2px white;
}

.ContentWrapper {
  height: calc(600px - 145px);
  position: relative;
}

.TextInput {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 0 0 0;

  resize: none;
  border: none;
  overflow: auto;
  outline: none;
  box-shadow: none;
}
.TextInput.Headline {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
}
.TextInput.Paragraph {
  font-size: 16px;
  line-height: 22px;
}

.TextInput::placeholder {
  color: var(--color-gray-600);
}

.SmileIcon {
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: visibility, opacity;
  transition-delay: 0.25s;
  transition-duration: 0.1s;
  border-radius: 16px;
  padding: 8px;
  height: 38px;
  width: 38px;
}

.ShowPicker .SmileIcon,
.ShowPicker .SmileIcon,
.ContentWrapper:focus-within .SmileIcon {
  visibility: visible;
  opacity: 1;
}

.EmojiPickerWrapper {
  position: absolute;
  top: -48px;
  right: 23px;
  visibility: hidden;
  opacity: 0;
  transition: visibility, opacity;
  transition-delay: 0.25s;
  transition-duration: 0.1s;
}
@-moz-document url-prefix() {
  .EmojiPickerWrapper {
    top: -136px;
  }
}
.ShowPicker.EmojiPickerWrapper,
.ContentWrapper:focus-within .EmojiPickerWrapper {
  visibility: visible;
  opacity: 1;
}

.TextareaWrapper {
  height: 100%;
}