.ButtonTitle {
  color: var(--color-gray-900);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.ButtonSubTitle {
  color: var(--color-green-900);
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  text-align: left;
}

.ConnectedGoogleSheet {
  border-radius: 14px;
  border: 1px solid var(--color-gray-250);
}

.IconWrapper {
  height: 38px;
  width: 38px;
  border-radius: 10px;
  background: #dff4ea;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 16px;
    height: 23px;
    margin-right: -3px;
  }
}

.ConnectedGoogleSheetStatus {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px 10px 15px 15px;
  height: 74px;
  gap: 10px;
  border-bottom: 1px solid var(--color-gray-250);
}

.ConnectedGoogleSheetStatusState {
  display: flex;
  align-items: center;
  gap: 10px;
}

.spreadSheetLinkSection {
  padding: 15px;
}

.Label {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: var(--color-gray-500);
  display: inline-block;
  margin-bottom: 5px;
}

.spreadSheetLink {
  position: relative;
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 46px;
  font-weight: 400;
  border-radius: 12px;
  height: 46px;
  padding: 0 50px 0 15px;
  color: var(--color-gray-600);
  background: var(--color-gray-150);

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.LinkIcon {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 15px;
  z-index: 1;
  color: var(--color-gray-900);
}

.Menu {
  margin-top: -5px;
  color: var(--color-gray-700);
}

.UnLinkButton {
  font-size: 14px;
  line-height: 18px;
  color: var(--color-others-alert-600);
}

.UnLinkIcon {
  font-size: 18px;
  margin-right: 7px;
  display: inline-block;
}
