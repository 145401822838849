.Root {
  background: var(--color-purple-150);
  display: flex;
  min-height: 58px;
  padding: 10px 15px 10px 20px;
  align-items: center;

  color: var(--color-gray-900);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.PagePreview {
  border-radius: 25px 25px 0 0;
  position: absolute;
  width: 100%;
  top: 0;
  justify-content: space-between;
}

.isEmpty {
  margin-bottom: -58px;
}

.UpgradeText {
  margin-right: 15px;
}

.KingIcon {
  margin-top: -2px;
}