.Root {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.Avatar {
    text-transform: uppercase;
    margin-right: 10px;
    border-radius: 15px;
    font-size: 16px;
}

.Name {
    font-size: 14px;
    line-height: 16px;
    color: #222222;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}