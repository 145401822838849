.Root {
    height: 570px !important;
}

.Body {
    width: 610px;
    padding-top: 20px;
}

.DragText {
    color: var(--color-gray-500);
    font-size: 14px;
    line-height: 18px;
}