
.SelectContainer {
  display: inline-block;
  position: relative;
  width: 200px;
}


.SelectedText {
  height: 38px;
  background-color: white;
  padding: 0px 15px;
  line-height: 20px;
  cursor: pointer;
  border-radius: 16px;
  border: solid 2px transparent;
  transition: border-color 0.3s ease;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.SelectContainer:hover .SelectedText {
  border-color: var(--color-purple-400);
}

.SelectContainer .SelectedText.Active {
  border-color: var(--color-purple-600-primary);
}

.SelectedText::after {
  content: "";
  position: absolute;
  right: 15px;
  top: 16px;
  border-style: solid;
  border-width: 6px 5.5px 0 5.5px;
  border-color: var(--color-purple-700) transparent transparent transparent;
  transition: all 0.3s ease;
  transform-origin: center;
}
.SelectedText.Active::after {
  /*
  border-width: 0 5.5px 6px 5.5px;
  border-color: transparent transparent var(--color-purple-700) transparent;
  */
  transform: rotate(-180deg)
}

.SelectOptions {
  margin: 0;
  padding: 10px;
  background: white;
  border-radius: 25px;
  position: absolute;
  width: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.ShowDropdownOptions {
  min-height: 50px;
  opacity: 1;
  visibility: visible;
  overflow: hidden;

  animation: Animation 0.3s ease;
  -webkit-font-smoothing: antialiased;
  transform-origin: top;
}

.HideDropdownOptions {
  min-height: 0;
  opacity: 0;
  visibility: hidden;
}

@keyframes Animation {
  0% {
      transform: translateY(-10px) scaleY(0.9);
      opacity: 0;
  }
  100% {
      transform: none;
      opacity: 1
  }
}