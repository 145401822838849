.HeaderControlsRoot {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% + 20px);
}

.Space {
    width: 38px;
}

.HeaderControlsTitle {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    align-self: center;

    h3 {
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        margin-left: 20px;
    }
}