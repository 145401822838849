.Root {
  position: relative;
  border-radius: 25px;
  margin: 10px 15px;
  padding: 15px 15px 15px 15px;
  background-color: var(--color-purple-100);
}

.CloseButton {
  position: absolute;
  right: 15px;
  top: 16px;
}

.Title {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: var(--color-gray-900);
  padding-right: 30px;
}

.ActionButton {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--color-purple-700);
  margin-top: 10px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}
