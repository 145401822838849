.Root {
  color: var(--color-gray-900);
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SecondaryButton {
  button {
    background: #ffffff;
  }
}
