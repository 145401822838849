.TitleContainer {
    padding-bottom: 10px;
}

.Title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: var(--color-gray-900);
}


.SubTitle {
    padding-top: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: var(--color-gray-500);
}
