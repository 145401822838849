.Container {
  min-width: 0;
  width: 100vw;
  height: 100vh;

  display: grid;
  grid-template-rows: 70px 60px calc(100% - 130px);
  grid-template-columns: 3fr 2fr;
  grid-template-areas:
    'title title'
    'contentHead previewHead'
    'main aside';

  background: white;
}

.WithMarginShift {
  margin-top: 200%;
}

.Container .Title {
  grid-area: title;
  background: white;
}

.ContentHead {
  grid-area: contentHead;

  border-style: solid;
  border-width: 1px 0px 1px 0px;
  border-color: var(--color-gray-200);

  background: white;
}
.PreviewHead {
  grid-area: previewHead;
  background-color: var(--color-gray-100);

  border-style: solid;
  border-width: 1px 0px 1px 1px;
  border-color: var(--color-gray-200);

  background: white;
}

.Content {
  grid-area: main;

  flex: 1 1;

  background: white;
}
.Preview {
  grid-area: aside;
  background-color: var(--color-gray-100);

  border-style: solid;
  border-width: 0px 0px 0px 1px;
  border-color: var(--color-gray-200);
}

.EditorContent {
  height: 100%;
  display: flex;
}

.EditorContentWithBanner {
  padding-bottom: 78px;
}

.Bold {
  font-weight: 700;
}

.TipBanner {
  width: calc(60% + 20px);
  min-width: 340px;
  max-width: 740px;
  margin: 20px auto -10px;
  padding-left: 20px;
  padding-right: 20px;
}
