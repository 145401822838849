.Root {
    max-width: 360px;
}

.Centred {
    text-align: center;
}

span.Icon {
    font-size: 120px;
    margin-top: -50px;
    margin-bottom: 10px;
}

div.Body {
    padding-top: 0;
}