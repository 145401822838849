.Root {
    width: 300px;
    margin-left: -10px;
    margin-right: -10px;
}

ul.List {
    width: 100%;
    padding: 20px 0 0;
}

li.ListItem {
    cursor: pointer;
    width: 100%;
}

.ListItemIcon {
    color: var(--color-gray-800);
    margin-right: 10px;
}

.Title {
    color: #222;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
}