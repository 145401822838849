.BlueBackground {
  height: 100%;
  margin-left: 30px;
  background: var(--color-purple-100);
}

.SquaresBackground {
  background-image: url('../icons/Background.svg');
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: -30px;
  padding-left: 30px;
}

.Phone {
  max-width: 80%;
  max-height: 80%;
}