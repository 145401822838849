.Inline {
  display: flex;
  align-items: center;
}

.Icon,
.Inline .Icon:disabled {
  font-size: 24px;
  width: 38px;
  transition: unset;
  color: unset;
}

.PagesBoardIcon svg {
  width: 28px;
  height: 28px;
}

.PagesBoardIcon {
  margin-right: 5px;
  transition: unset;
}

.Members {
  font-size: 14px;
  line-height: 19px;
  height: 38px;
  align-self: flex-end;
  display: flex;
  align-items: center;

  color: var(--color-gray-500);
}
