
.Root {
  display: inline-block;
  width: var(--node-width);
  position: absolute;
  border-radius: 19px;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  transition: left 0.3s, top 0.3s;
  height: 100%;
}