.Root {
    max-width: 360px;
}

.Text {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-gray-900);
    margin-top: 10px;
    margin-bottom: 10px;
}

span.Icon {
    font-size: 120px;
    margin-top: -50px;
}

div.Body {
    padding-top: 10px;
}