.Root {
  position: relative
}
.Image {
  max-height: 60px;
  max-width: 100%;

  border-radius: 10px;

  vertical-align: middle;
  min-width: 55px;
  object-fit: cover;
}

.LoadingWidth {
  width: 60px;
  height: 60px;
}

.VideoWrapper {
  position: relative;
  display: inline-block;
}

.PlayBtnIcon {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}