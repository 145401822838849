.Scrollable {
  max-height: 100%;
  width: 100%;
  height: auto;
  overflow-y: auto;
  transition: all 0.3s ease;
}

.Visible {
  position: relative;
  top: 0;
  left: 0;
  transform: translateX(0);
  transition:
    height 0.4s ease,
    transform 0.4s ease;
  scroll-behavior: smooth;
}

.Hidden {
  position: absolute;
  transform: translateX(-100%);
}

.NoMemberContainer {
  padding: 48px 14px 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.NoMemberIcon {
  width: 65px;
  height: 65px;
}

.NoMemberText {
  color: var(--color-gray-700);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
