.Root {
  border-radius: 13px;
  margin: 0px 4px 8px 4px;
  display: flex;
  align-items: center;
  
  color: #7D7D7D;
  font-size: 16px;
  font-weight: 500;
  padding-right: 3px;
  
  transition: all .75s ease;
  background: #F5F5F5;
  border: solid 1px #F5F5F5;
}

.Icon {
  background: #71C963;
  color: white;
  
  border-radius: 50%;
  height: 26px;
  width: 26px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}
.Label {
  padding: 0px 7px;
  max-width: 220px;
  text-overflow:ellipsis;
  overflow: hidden;
}
.CloseIcon {
  cursor: pointer;
}

.Root.error-outlined {
  border: solid 1px #E33922;
}