.Root {
  color: #222222;
  width: 100%;
  font-size: 22px;
  outline: 0px solid transparent;
  border: 0px solid transparent;
  background-color: transparent;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.25px;
  margin-bottom: 10px;
  text-align: left;
  max-height: 80px;
  overflow-y: auto;
}

.Loading {
  position: absolute;
  right: 0px;
  width: 31px;
}

.Root[data-placeholder]:empty:before{
  content: attr(data-placeholder);
  color: #999;
  cursor: text;
}

.Input {
  color: #222;
  width: 100%;
  font-size: 22px;
  outline: 0 solid transparent;
  border: 0 solid transparent;
  background-color: transparent;
  font-style: normal;
  font-weight: 700;
  letter-spacing: .25px;
  margin-bottom: 10px;
  text-align: left;
  max-height: 80px;
}

.Wrapper {
  position: relative;
}
.EmojiPickerWrapper {
  position: absolute;
  top: -48px;
  right: 23px;
}
.SmileIcon {
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: visibility, opacity;
  transition-delay: 0.25s;
  transition-duration: 0.1s;
  border-radius: 16px;
  padding: 8px;
  height: 38px;
  width: 38px;
}
.SmileIcon:hover {
  background: #F2F2F2;
}
.SmileIcon:active {
  background: #E0E0E0;
}

.ShowPicker .SmileIcon,
.ShowPicker .SmileIcon,
.Wrapper:focus-within .SmileIcon {
  visibility: visible;
  opacity: 1;
}

.TextAreaResizableWrapper {
  text-align: left;
}