.Root {
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    color: var(--color-gray-900);

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}