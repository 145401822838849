.HeaderWrapper {
  padding: 15px 21px 18px 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--color-gray-200);
  border-bottom: 1px solid var(--color-gray-200);
  position: sticky;
  top: 0;
  left: 0;
  background: var(--secondary-color);
}

.Logo {
  height: 26px;
}

.Button {
  background: transparent;
  color: var(--color-purple-700);
  height: 22px;
  line-height: 22px;
  font-size: 16px;
  font-weight: 500;
  padding: 0;
}

.Button:not(:disabled):hover,
.Button:not(:disabled):active {
  background: transparent;
}
