.Root {
  background: var(--color-purple-150);
  width: 360px;
}

.DialogRoot {
  background: var(--color-purple-150);
}

.DialogRoot > span > button:hover,
.DialogRoot > span > button:active {
  background: transparent;
}

.IconContainer > div > span {
  bottom: 108px;
}

div.Container {
  background: var(--secondary-color);
  padding: 30px;
}

div.Content {
  margin: 0;
}

div.Actions {
  margin: 30px 0 0;
}

.Message {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: var(--color-gray-900);
  text-align: center;
}

span.Icon {
  font-size: 120px;
  margin-top: -40px;
}