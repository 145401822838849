.Root {
  display: flex;
  align-items: center;
  gap: 10px;

  cursor: pointer;
}

.Title {
  color: var(--color-gray-900);
  word-break: break-word;

  font-size: 14px;
}

.Root .Button svg {
  vertical-align: middle;
}
.Root .Button path {
  fill: var(--color-purple-700);
}

.Root:hover .Button {
  background-color: var(--color-purple-200);
}

.Disabled {
  opacity: 0.5;
}