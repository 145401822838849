.Line {
  background: var(--color-gray-300);
  height: 1px;
  width: 100%;
}

.Row {
  display: flex;
  width: 100%;
  align-items: center;
}

span.KingBadge {
  font-size: 22px;
  margin-left: 5px;
  margin-top: 3px;
}

.RadioInputWrapper span:hover,
.RadioInputWrapper input:hover {
  cursor: pointer;
}

button.ConnectButton {
  position: relative;
  font-weight: 500;
}

.LightSubTitle {
  color: var(--color-gray-500);
}

.OnlyOwn {
  font-size: 14px;
  line-height: 18px;
  color: #222222;
  background-color: var(--color-purple-100);
  padding: 15px;
  border-radius: 14px;
}

.GoogleSheetIcon {
  margin-right: -2px;
}

span.Icon {
  vertical-align: center;
  color: #5dabff;
  margin-right: 8px;
  margin-top: -2px;
}

span.SpinnerIcon {
  margin-left: 8px;
  color: #0f9c57;
}
