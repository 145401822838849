.Root {
  position: relative;
  margin-bottom: 90px;

  --node-background: var(--color-gray-150);
  --node-title-color: var(--color-gray-900);
  --node-title-fontsize: 16px;
  --node-title-lineheight: 18px;
  --node-title-opacity: 1;
  --node-text-color: var(--color-gray-700);
  --node-text-fontsize: 14px;
  --node-text-lineheight: 18px;
  --node-padding: 15px;
}

.EditorRoot {
  margin-bottom: 45px;
}

.SharedRoot {
  margin-bottom: 15px;
}