.Root {
  margin-top: 15px;
}

.Attachment {
  position: relative;
}

.AttachmentBorder {
  box-shadow: 0 0 0 1px var(--secondary-color);
}

.AttachmentPdf {
  border: 1px solid var(--color-gray-150);
}

.AttachmentVideo > div > div > span {
  font-size: 65px;
}

.ActionButtonWrapper {
  margin-top: 10px;
}

.ActionButton {
  margin-top: 10px;
  color: var(--color-gray-500);
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
}

.NotFound {
  display: flex;
  height: 64px;
  align-items: center;
  padding: 0 20px 0 20px;
  gap: 10px;
  border-radius: 19px;
  margin-top: 15px;
  background-color: var(--color-gray-150);
}

.NotFoundText {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: var(--color-gray-600);
}
