.Root {
    margin-top: 10px;
    margin-bottom: 20px;
}

.Row {
    position: absolute;
    
    width: 100%;
    padding: 0px 10px 8px 10px;

    display: flex;
    justify-content: space-between;
    column-gap: 20px;
}

.Column {
    position: absolute;
    
    width: 100%;
    padding: 0px 10px 8px 10px;

    display: flex;
    justify-content: space-between;
    column-gap: 20px;
}

.MemberItem {
    width: calc(50% - 10px);
}