.Root {
  min-height: 65px;
}

.Wrapper {
  display: flex;
  align-items: flex-start;
  padding: 2px;
  border-bottom: 1px solid #979797;
}

.Wrapper:focus-within {
  border-bottom: 2px solid var(--color-purple-700);
  padding: 2px 2px 1px 2px;
}

.Root.Error .Wrapper {
  border-bottom: 2px solid #E33922;
  padding: 2px 2px 1px 2px;
}


.InputWrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  background-color: #fff;
  border-radius: 3px;
  flex-grow: 1;
  max-height: 170px;
  overflow-y: auto;
}

.Input,
.InputWrapper {
  cursor: auto;
}

.Input {
  border: 1px solid transparent;
  flex-grow: 1;
  width: 30px;
  color: rgba(0,0,0,.8);
  height: 26px;
  margin-bottom: 8px;
}

.ErrorWrapper {
  height: 30px;
  padding-top: 5px;
}

.ErrorMessage {
  color: #E33922;
  font-size: 14px;
}