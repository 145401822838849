.CheckoutHeaderControlsRoot {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% + 20px);
}

.Space {
    width: 38px;
}

.CheckoutHeaderControlsTitle {
    text-align: center;
    margin-left: auto;
    align-self: center;

    h3 {
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
    }
}

.CheckoutHeaderControlsClose {
    align-self: flex-start;
    margin-left: auto;
    margin-top: 0;
    margin-right: 0;
}