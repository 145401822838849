.Root {
  width: 900px;
  background-color: var(--color-purple-100);
  position: fixed;
  bottom: 30px;
  padding: 0 15px;
  z-index: 999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 0 25px 25px;
  height: 70px;
}

.SelectedLabel {
  color: var(--color-gray-900);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-left: 6px;
}

.ButtonsContainer {
  display: flex;
  flex-direction: row;

  & button {
    min-width: 61px;

    &:first-of-type {
      margin-right: 15px;
    }
  }
}
