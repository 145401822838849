@media only screen and (min-width: 681px) {
  .Root {
    max-width: 746px;
    padding: 0px 0px 100px 30px;
    /*margin-bottom: 300px;*/
  }
}

@media only screen and (max-width: 680px) {
  .Root {
    padding: 0;
  }
}
