.Wrapper {
    position: relative;
}

textarea.TextArea {
    min-height: 150px;
    padding-right: 13px;
    padding-left: 13px;
    padding-bottom: 50px;
    transition: none;
    overflow: hidden;
}

.Wrapper:focus-within .Input {
    padding-right: 50px;
}

.EmojiPickerWrapper {
    position: absolute;
    bottom: 10px;
    right: 10px;
}


.PickerButton:hover {
    background: var(--color-gray-200);
}

.PickerButton:active {
    background: var(--color-gray-300);
}

button.PickerButton {
    font-size: 18px;
    color: var(--color-gray-900);
    opacity: 0;
    transition: opacity .3s;
}

.ShowPicker .PickerButton,
.Wrapper:focus-within .PickerButton {
    visibility: visible;
    opacity: 1;
}

.TextAreaResizableWrapper {
    text-align: left;
}