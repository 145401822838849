.Wrapper {
  position: relative;
}
.Wrapper:focus-within .Input {
  padding-right: 80px;
}
.EmojiPickerWrapper {
  position: absolute;
  bottom: 0;
  right: 5px;
}
button.PickerButton {
  font-size: 18px;
  color:var(--color-gray-900)
}
button.PickerButton:hover {
  background: var(--color-gray-200);
}
button.PickerButton:active {
  background: var(--color-gray-300);
}

.TextAreaResizableWrapper {
  text-align: left;
}