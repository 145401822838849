.ConfetisBackground {
    background: var(--color-purple-100);
    min-height: 100dvh;
    z-index: 1;
    background-image: url('../icons/confetisBackground.svg');
    background-repeat: repeat-x;
    padding-bottom: 60px;
}

.Mobile{
    height: 100dvh;
}

.MobileContent {
    height: calc(100dvh - 56px);
    position: relative;
    
}

.ConfettiContainer {
    width: 100%;
    position: absolute;
    top: 0px;
    overflow: hidden;
    z-index: -1;
}

.BubbleContainer {
    height: 100dvh;
    z-index: 1;
    overflow: auto;
    padding-bottom: 30px;
}