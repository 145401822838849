.Icon {
  vertical-align: middle;
  color: #222222;
}

.ItemIcon {
  margin-right: 10px;
  font-size: 20px;
  color: var(--color-gray-800);
}

.Centred {
  margin-top: -3px;
}

.Menu {
  margin-top: 5px;
}

.MarginButton {
  margin-right: 10px;
}

span.DeleteIcon {
  margin-top: -30px;
  font-size: 120px;
  margin-bottom: 10px;
}