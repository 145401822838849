.Timer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 9px;

  background-color: var(--color-purple-300);
  margin-top: 10px;
  width: 119px;
  height: 34px;
  border-radius: 11px;
  padding: 11px 14px 13px 14px;

  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  color: var(--color-gray-900);
}

.Separator {
  height: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.Separator::before,
.Separator::after {
  content: '';
  width: 3px;
  height: 3px;
  background-color: var(--color-gray-900);
  border-radius: 50%;
  position: absolute;
}

.Separator::before {
  top: 0;
}

.Separator::after {
  bottom: 0;
}
