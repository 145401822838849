.Root {
  height: 30px;
  display: inline-flex;
  align-items: center;
  line-height: 22px;
}

.Discount {
  margin-left: 5px;
  margin-top: 1px;
  color: var(--color-brand-marketing_purple_400);
  font-size: 12px;
  font-weight: 600;
}
