.Wrap {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.TopContentWidgetsItem {
  flex: 1;
    
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ChartItem {
  flex: 1;
  flex-grow: 2;

  display: flex;
  flex-direction: column;
  justify-content: center;
}