.Link {
    color: var(--color-purple-600-primary);
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    display: inline-block;
    margin-top: 10px;
}

.HeaderTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: var(--color-gray-900);
}

.ModalSize {
    width: 650px;
    max-height: 595px;
}