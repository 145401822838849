.Header {
  grid-area: header;
}
.DesktopPlaceholder {
  grid-area: aside;
}
.Content {
  grid-area: article;

  display: flex;
  justify-content: center;
  overflow-y: auto;
  height: calc(100vh - 73px);
  scroll-padding-top: 50px;
}

@media only screen and (min-width: 681px) {
  .Container {
    display: grid;
    grid-template-areas:
        "header aside"
        "article aside";
    grid-template-rows: 73px 1fr;
    grid-template-columns: 1fr 40%;
    height: 100vh;
    margin: 0;
  }

  .MobilePlaceholder {
    display: none;
  }

  .DesktopPlaceholder {
    height: 100vh;
  }
  
  .Logo {
    height: 73px;
  }
}

@media only screen and (max-width: 680px) {
  .Content {
    height: calc(100vh - 109px);
  }

  .Container {
    display: grid;
    grid-template-areas:
      "header"
      "article";
    grid-template-rows: 109px 1fr;

  }

  .MobilePlaceholder {
    width: 100%;
    height: 15vh;
  }

  .DesktopPlaceholder {
    display: none;
  }

  .Content{
    padding: 20px;
  }
}