.Root {
  display: flex;
  justify-content: space-between;
  padding: 20px 15px;
  z-index: 1;
  align-items: center;
  height: 68px;

  font-size: 14px;
}

.Root.Highlight {
  padding: 15px 20px;
  background: var(--color-purple-700);
}