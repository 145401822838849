.Root {
  align-items: center;
  font-size: 18px;
  line-height: 27px;
  transition: all 0.3s ease;
  box-shadow: 0 -2px 0 0 transparent;
}

.DraggedTo {
  box-shadow: 0 -2px 0 0 var(--color-purple-400);
  z-index: 1;
}