.Root {
  position: relative;
  width: 100%;
  min-width: 300px;

  display: flex;

  padding: 15px 35px 15px 0px;
  background: var(--color-orange-50);
  border-radius: 14px;
}

.LightbulbTipIcon {
  padding: 0 11px;
  font-size: 22px;
}

.Content {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.Title {
  color: var(--color-gray-900);

  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.Description {
  color: var(--color-gray-900);

  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.CloseBtn {
  position: absolute;
  top: 4px;
  right: 4px;
}

.CloseBtn path {
  color: var(--color-gray-800);
}