.Root {
  width: 900px;
  background-color: var(--secondary-color);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 30px;
  padding: 15px 21px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  color: var(--color-gray-900);
  border-radius: 0 0 25px 25px;
}
