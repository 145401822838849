.Prices {
    display: flex;
    gap: 15px;
    margin-top: 20px;
    margin-bottom: 20px;

    & > div {
        width: 50%;
    }
}

.PaymentProvider {
    margin-bottom: 35px;
}

.ButtonLabel {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin-bottom: 8px;
    color: #999;
}

.KingBadge {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    font-size: 22px;
    margin-left: 5px;
    margin-top: -5px;
}