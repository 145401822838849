.Root {
  background: var(--color-purple-150);
  justify-content: space-between;
  display: flex;
  min-height: 58px;
  padding: 13px 12px;
  align-items: center;

  border-radius: 20px;
  margin: -25px 0 10px;
}

.UpgradeText {
  color: var(--color-gray-900);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
