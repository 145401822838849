.Root {
  display: flex;
  height: 45px;
}

.IconDisabled path {
  opacity: 0.6;
}

.Tooltip { 
  background: black;
  margin-top: 0px;
}