.CarouselContainer {
  position: relative;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  margin: 0 auto;
}

.Carousel {
  overflow: visible;
  width: 100%;
  padding: 0 30px;
}

.CarouselTrack {
  transition: transform 0.2s ease-in-out;
}

.CarouselContainer .Arrow path {
  fill: var(--color-purple-700);
}
.CarouselContainer .Arrow {
  width: 46px;

  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  z-index: 1;

  border-radius: 19px;

  color: var(--color-purple-700);
  background: var(--color-purple-100);
  box-shadow: -3px 5px 15px 0px #00000026;
}
.CarouselContainer .Arrow:hover {
  background: var(--color-purple-150);
}
.CarouselContainer .Arrow:active {
  background: var(--color-purple-200);
}
.CarouselContainer .Arrow:disabled {
  background: var(--color-purple-100);
  pointer-events: auto;
  box-shadow: none;
}
.CarouselContainer .Arrow:disabled path {
  opacity: 0.2;
}

.PrevIcon {
  left: 20px;
}
.NextIcon {
  right: 20px;
}
