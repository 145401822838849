.Products {
    margin: 20px 0 15px;
}

span.DeleteProductIcon {
    font-size: 120px;
    margin-bottom: 10px;
}

span.DeleteFieldIcon {
    font-size: 120px;
    margin-top: -30px;
    margin-bottom: 10px;
}

div.DeleteProductPopUp {
    margin-top: -30px;
}