.Label {
    display: block;
    position: relative;
    width: auto;
}

.Hidden {
    display: block;
    visibility: hidden;
    white-space: pre;
    background-color: transparent;
}

.Input {
    outline: none;
    font-weight: 500;
    color: var(--color-gray-900);

    border: 2px solid transparent;
    border-radius: 16px;
    appearance: none;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    transition: border 0.2s ease;
    background-color: transparent;
}

.Input:not(:read-only):focus,
.Input:not(:read-only):hover {
    border-color: #EFEFEF
}

.Input::placeholder {
    color: #999999;
    font-weight: 500;

    resize: none;
    overflow-y: hidden;
    outline: 0 solid transparent;
    border: 0 solid transparent;
    background-color: transparent;
    border-radius: 0;
    text-overflow: ellipsis;
}

.Input:disabled {
    color: inherit;
}

.Input,
.Hidden {
    margin: 0;
    padding: 0;
    font-weight: 500;
}

.Big .Input,
.Big .Hidden {
    padding: 6px 5px;
    height: 38px;
    font-size: 24px;
    line-height: 28px;
}

.Big .Input::placeholder {
    font-size: 24px;
    line-height: 24px;
}

.Default .Input,
.Default .Hidden {
    padding: 4px;
    height: 38px;
    font-size: 16px;
    line-height: 28px;
}

.Default .Input::placeholder {
    color: #999999;
    font-size: 16px;
    line-height: 16px;
}