.DropdownMenu {
    margin-top: 5px;
}

.CancelSubscription {
    color: var(--color-others-alert-600);
}

.Menu {
    min-width: 150px;
}