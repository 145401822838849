.Root {
  position: relative;
  height: 100%;
  overflow-x: hidden;
}

.Scroll {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.Start::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 6px;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0),
    rgba(0, 0, 0, 0.07)
  );
  z-index: 10;
}
.End::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0px;
  height: 6px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(0, 0, 0, 0.07)
  );
  z-index: 10;
}

@media (-moz-os-version: windows-xp),
  (-moz-os-version: windows-vista),
  (-moz-os-version: windows-win7),
  (-moz-os-version: windows-win8) {
  .Start::before,
  .End::after {
    right: 7px;
  }
}
