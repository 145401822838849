.Root {
    margin-bottom: 20px;
}

.Label {
    font-size: 14px;
    font-weight: 400;
    color: var(--color-gray-500);
}

.Buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;

    margin: 10px 0;
}