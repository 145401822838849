.Root {
  display: flex;
  align-items: center;

  gap: 10px;

  height: 100%;
  min-width: 0;
}


.PageLink {
  color: var(--color-gray-900);
  padding: 0px 5px 0px 15px;
}

.TooltipBottom {
  display: inline-block;
}

.LinkWrapper {
  display: flex;
  align-items: center;
  column-gap: 5px;

  width: 100%;
  min-width: 0;
  background: var(--color-gray-100);
  border-radius: 16px;

  font-size: 14px;
}
.Label {
  width: 85px;
  padding-left: 15px;
}

.Link {
  color: var(--color-gray-600);
  line-height: 24px;
  
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
  align-items: center;
  min-width: 200px;
}

.Button {
  color: var(--color-purple-700);
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
}