.H1 {
  font-size: 30px;
}

.H5 {
  font-size: 18px;
  font-weight: 500;
}

.H6 {
  font-size: 15px;
  font-weight: 500;
}

.Subheading1 {
  font-size: 24px;
}
.subheading2 {
  font-size: 16px;
}

.Title,
.Subtitle {
  font-style: normal;
  font-size: 18px;
  line-height: 23px;
}

.Title {
  font-weight: 700;
}

.Subtitle {
  font-weight: 400;
}