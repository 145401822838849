.Hidden {
    height: 0;
    overflow: hidden;
}

.Title {
    font-size: 14px;
    line-height: 19px;
    color: var(--color-gray-500);
    margin: 15px 20px 10px;
}