.MembersCloseIcon path {
  font-size: 20px;
  color: var(--color-gray-800);
}

.MembersCount {
  font-size: 16px;
  font-weight: 400;
  color: var(--main-color);
  text-transform: capitalize;
}

button.MembersDeleteIcon, button.MembersDeleteIcon:hover {
  font-size: 20px;
  background: transparent;
}
