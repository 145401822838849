.Root {
  background-color: var(--color-purple-100);
  border-radius: 25px;
  padding: 15px;
  margin: 10px 15px;
}

.Header {
  display: flex;
  justify-content: space-between;
}

.ChromeIcon {
  width: 32px;
}

.Message {
  margin: 10px 0;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-gray-900);
}

.Message > span {
  font-weight: 700;
}

.Forward {
  font-size: 14px;
  line-height: 18px;
  color: var(--color-purple-700);
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 2px;
}

.Close {
  width: 16px;
  height: 16px;
  color: var(--color-purple-500);
}
