.Root {
    padding: 20px 10px 10px;
    color: var(--color-gray-900)
}

.Title{
    font-size: 24px;
    font-weight: 500;
    text-align: center;
}

.Email{
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin-top: 15px
}

.List{
    list-style: decimal;
    list-style-position: inside;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
}

.Button{
    margin-top: 40px;
    width: 100%;
    height: 46px;
    border-radius: 18px;
}