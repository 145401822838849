.Root {
  width: 520px;
  margin: 0 auto;
}

.Page {
  position: relative;
  min-height: calc(100vh - 128px);
  max-width: 520px;
  min-width: 340px;
  margin: 0 auto;
  border-radius: 36px;
  overflow: hidden;
  height: 100%;
  background-color: white;
}

.EmptyPage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageWithBanner {
  position: relative;
  padding-top: 58px;
}

.NoText {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: white;
  text-align: center;
}

.FolderLoadingSpinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: calc(100vh - 245px);
}

.NoNodesContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  color: var(--color-gray-500);
}

.NoNodesContainer svg {
  margin-bottom: 30px;
  width: 195px;
  height: 232px;
}
