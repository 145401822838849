.Updates {
  height: 100%;
  width: 0;
  min-width: 0;
  position: relative;
  transition:
    width 0.3s ease,
    min-width 0.3s ease;
  overflow: hidden;
}

.UpdatesIsOpened {
  width: 385px;
  min-width: 385px;
}

.UpdatesWithoutAnimation {
  transition: none;
}
