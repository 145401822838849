@keyframes fromTop {
    0% {
        top: -100%
    }

    100% {
        top: 0;
    }
}

.Root {
    width: 100%;
    position: relative;

    animation: fromTop 0.3s ease;
}

.Root + main {
    height: calc(100vh - 58px);
}