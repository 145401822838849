.Root {
  position: relative;
  height: 60px;
  min-width: 60px;
}

.RootContainer {
  position: relative;
  max-height: 60px;
  height: 100%;
}

.ImageContainer {
  display: flex;
  justify-content: flex-start;
  position: relative;
  height: 100%;
}

.Image {
  height: 60px;
  border-radius: 10px;
  aspect-ratio: 1.34;
  object-fit: cover;
  object-position: center;
}

.LoadingWidth {
  width: 60px;
}

.PdfIcon {
  position: absolute;
  z-index: 2;
  bottom: 6px;
  left: 6px;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.2));
}
