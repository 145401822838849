.Root {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.Root input:disabled {
    color: var(--color-gray-900);
}

.CharactersCount {
    margin: 0 5px 8px;
    white-space: nowrap;
    align-self: flex-end;

    font-size: 16px;
    line-height: 19px;
    color: #999999;
}

.CharactersCountBig {
    margin: 0 5px 10px;
}