.InfoContainer {
    width: 450px;
}

.Row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
}

.RowBorder {
    width: 100%;
    border-bottom: solid 1px var(--color-gray-200);
}

.Plan {
    color: var(--color-grays900);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
.PlanName {
    color: var(--color-grays900);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.PlanPrice {
    color: var(--color-grays900);
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.PlanExpiresMessage {
    color: var(--color-others-alert-600);
}