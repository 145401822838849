.Root {
    position: relative;
    margin-left: 5px;
}

.Arrow {
    position: absolute;
    top: calc(100% + 60px);
    left: -15px;
    margin: auto;
}