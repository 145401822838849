.Root {
  height: 60px;
}

.Input {
  border: 0px solid transparent;
  border-bottom: solid 1px var(--color-purple-600-primary);
  padding: 10px 0px;
  background-color: transparent;
  outline: 0px solid transparent;
  width: 100%;
  border-radius: 0;

  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.25px;
}
.Input:focus,
.Input:active {
  border-bottom: solid 2px var(--color-purple-600-primary);
  padding-bottom: 9px;
}

.Input::selection{
  background-color: var(--color-purple-400);
}

.InputError {
  border-bottom: solid 1px #ff0000;
}
.InputError:focus,
.InputError:active {
  border-bottom: solid 2px #ff0000;
  padding-bottom: 9px;
}

.Error {
  color: #ff0000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  margin-top: 6px;
}

@media only screen and (max-width: 680px) {
  .Root {
    height: 100%;
  }

  .Input {
    line-height: 22px;
    padding: 10px 0;
    font-size: 16px;
  }

  .Input:focus,
  .Input:active {
    padding: 10px 0;
  }
}
