.Root {
  background-color: var(--color-purple-100);
  border-radius: 25px;
  margin: 10px 15px;
  position: relative;
  height: 134px;
  padding-left: 15px;
  overflow: hidden;
}

.Header {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 10;
}

.Content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.Title {
  margin-top: 18px;
  display: flex;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;

  color: var(--color-gray-900);
  align-items: center;
}

.Text {
  max-width: 150px;
  font-size: 14px;
  font-weight: 400;

  color: var(--color-gray-900);
}

.Title .Icon {
  margin-right: 6px;
}

.Message {
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.Cover {
  position: relative;
}

.Banner {
  height: 134px;
}

.PlayButton {
  position: absolute;
  z-index: 2;
  height: 134px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  width: 121px;
}

.Forward {
  font-size: 14px;
  line-height: 18px;
  color: var(--color-purple-700);
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 2px;
}
