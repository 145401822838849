
.Root {
  height: 100%;
  position: relative;
  border-radius: 22px;
  transition: background-color .25s ease-in-out;
}

/* WithMargin */
.WithMargin {
  padding: 10px;
}
.WithMargin:hover:not(.Selected),
.WithMargin.BlueVariant:not(.Selected) {
  background-color: var(--color-gray-200);
}

.Disabled {
  opacity: 0.5;
}
.Disabled .Overlay {
  cursor: default;
}

/* WithOverlay */
.Overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;

  transition: background-color .25s ease-in-out;
  opacity: 0.15;

  border-radius: 19px;
  z-index: 3;
}

.WithOverlay.WithDarkOverlay .Overlay {
  opacity: 0.2;
}

/* Default Variant*/
.DefaultVariant.WithOverlay:hover .Overlay {
  background-color: black;
}

/* Gray Variant */
.GrayVariant.WithOverlay:hover .Overlay {
  background-color: #000;
  cursor: pointer;
}

/* Gray Gradient */
.WithOverlay.WithGrayGradient:hover .Overlay {
  cursor: pointer;

  opacity: 1;

  background: rgb(0,0,0);
  background: linear-gradient(152deg, rgba(0,0,0,.25) 0%, rgba(255,255,255,0) 44%, rgba(255,255,255,0) 100%);
}
.WithOverlay.WithGrayGradient:hover:not(.Disabled) .Overlay {
  opacity: .5;
}

/* Blue Variant */
.BlueVariant.Selected.WithMargin,
.BlueVariant.Selected:not(.WithMargin) {
  --node-title-opacity: .7;
  --node-background: white;
}

.BlueVariant.Selected.WithMargin .Overlay,
.BlueVariant.Selected.WithOverlay .Overlay {
  background-color: var(--color-purple-550);
  cursor: pointer;
}

.BlueVariant.Selected.WithOverlay .Overlay {
  background-color: var(--color-purple-550);
}

/* Node Selection Radio Button */
.Radio {
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 3;

  display: none;
}

.Root:Hover .Radio,
.Root.Selected .Radio,
.Root.BlueVariant .Radio {
  display: block;
}

.Root.BlueVariant:hover .RadioUnselected {
  display: none;
}
.Root.BlueVariant:hover .RadioUnselectedHover {
  display: block;
}