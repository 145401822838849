@keyframes In {
  0% {
    transform: scale(0.75) translateY(5%);
  }
  100% {
    transform: scale(1) translateY(0);
  }
}
.Close {
  background: blue;
}

@media (max-height: 550px) {
  .Root {
    height: 550px;
  }
}

@media (min-height: 551px) {
  .Root {
    height: calc(100% - 100px);
    min-height: 550px;
    max-height: 800px;
  }
}

.Root {
  max-width: 100%;
  border-radius: 25px;
  position: relative;
  animation: In 0.2s ease;

  padding: 60px 0 70px;
  box-shadow: 0 4px 16px rgb(0 0 0 / 45%);
  background: #ffffff;
}
.Root.WithoutFooter {
  padding-bottom: 0px;
}

.CloseButton {
  position: absolute;
  z-index: 1;

  top: 10px;
  right: 10px;

  color: inherit;

  transition: all 0.2s ease;
}

.CloseButton svg {
  color: inherit;
}

.Header {
  position: absolute;
  top: 0;
  height: 60px;
  padding-left: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-gray-200);
}

.Body {
  height: 100%;
  overflow-x: hidden;
}

.Scroll {
  width: calc(var(--node-width) * 2 + 16px + 40px);
}

.Footer {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 70px;

  padding: 0 15px;

  background: white;
  border-radius: 0 0 25px 25px;
  border-top: 1px solid var(--color-gray-200);
}

.BackButton {
  position: absolute;
  z-index: 1;

  top: 10px;
  left: 10px;

  color: inherit;

  transition: all 0.2s ease;
}