.Root {
  padding-top: 5px;
  padding-bottom: 5px;
}

.Icon {
  margin-bottom: 10px;
}

.Value {
  color: var(--color-gray-900);
  font-weight: 900;
  font-size: 36px;
  height: 24px;
  margin-bottom: 5px;
  position: relative;
}

.Label {
  color: var(--color-gray-900);
  font-size: 16px;
  
  display: flex;
  align-items: center;
  gap: 5px;
}

.Label span {
  line-height: 24px;
}