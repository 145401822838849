.Root {
  border-radius: 25px;
  width: 650px;
  padding: 40px 25px;

  background: white;
  margin: 0 auto;
}

.Text {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  overflow-wrap: anywhere;
  white-space: break-spaces;
  color: var(--color-gray-900);
}

.Text a {
  color: var(--color-purple-700);
}