.Root {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;

  font-style: normal;

  font-size: 12px;
  line-height: 18px;
}
.Icon {
  font-size: 25px;
  margin: 10px 10px 10px 0;
}

.BoardTitle {
  display: block;
  color: var(--color-gray-900);
  font-weight: 400;
  font-size: 14px;
}
.Author {
  display: block;
  color: var(--color-gray-500);
  position: relative;
}

.Author::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -4px;
  background-color: var(--color-gray-500);
  width: 3px;
  height: 3px;
  border-radius: 50%;
  transform: translate(100%, -50%);
}

.Wrap {
  width: 100%;
  line-height: 20px;
}

.Margin {
  margin-bottom: 30px;
}

.DateAuthor {
  display: flex;
  align-items: center;
  gap: 11px;
}
