.DesktopHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 20px 10px;
  height: 77px;
}

.MobileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 56px;
  padding: 9px 15px 9px 20px;
  border-top: 1px solid var(--color-gray-200);
  border-bottom: 1px solid var(--color-gray-200);
}

.Avatar {
  border-radius: 15px;
  font-size: 16px;
  text-transform: uppercase;
}

.MobileHeader .Avatar {
  border-radius: 50%;
  font-size: 20px;
  font-weight: 500;
}

.DesktopHeader .BackBtn:hover {
  background: rgba(222, 238, 255, 0.5);
}

.DesktopHeader .BackBtn:active {
  background: rgba(209, 231, 255, 0.5);
}

.Email {
  margin-top: 7px;
  margin-bottom: 4px;
  line-height: normal;
  height: 38px;
  font-size: 16px;
  color: var(--color-gray-500);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.LogoutButtonMobile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  color: var(--color-gray-900);
  background: var(--secondary-color);
  width: 111px;
  height: 38px;
  border: none;
  cursor: pointer;
  column-gap: 15px;
}

.CloseButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 8px;
  width: 38px;
  height: 38px;
  border-radius: 16px;
}

.CloseButtonWrapper svg {
  color: var(--color-gray-900);
}