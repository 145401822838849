
.Root {
  overflow-y: auto;
}
.Root::placeholder {
  background: transparent;
}

.Root::-webkit-scrollbar-thumb {
  background: #a7a7a7;
  border-radius: 1ex;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  --scrollbar-width-height: 15px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}