.Root {
  display: flex;
  row-gap: 10px;
  flex-direction: column;
  align-items: flex-start;

  background: var(--color-gray-200);
  border-radius: 16px;
  padding: 10px 15px;
}

.UsageLabel{
  font-size: 14px;
  line-height: 20px;
  color: var(--color-gray-600);
}

.UsageLimits {
  font-size: 12px;
  line-height: 20px;
  color: var(--color-gray-600);
}

.UsageLimits span {
  font-weight: 600;
}
.Red .UsageLimits span {
  color: var(--color-others-alert);
}