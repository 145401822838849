.Root {
  margin: 0 auto;
  padding: 0 20px;
}

.Doc {  
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  gap: 15px
}

.Message {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;

  animation: 0s linear .2s forwards delayedShow;
  opacity: 0;
}

.Zoom {
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 

  position: absolute;
  bottom: 10px;
  
  width: 200px;
}

.Zoom button {
  background: gray;
  border-radius: 4px;
  padding: 10px;
  margin: 4px;
}

@keyframes delayedShow {
  to { opacity: 1; }
}