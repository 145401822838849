.Root {
  padding-bottom: 30px;
  height: auto;
}

.Header {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin: 0 30px 15px;
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: 30px;
}

.EmptyMessageOrLoadingContainer {
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 50px;
  position: relative;
}

.EmptyMessageIcon {
  width: 67px;
  height: 67px;
}

.EmptyMessageText {
  color: var(--color-gray-700);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
