.Item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 38px;
    padding: 0 20px;
    font-size: 14px;
    line-height: 19px;
    transition: all 0.3s ease;
}

.Item:hover {
    background-color: var(--color-purple-100);
}

.Icon {
    margin-right: 10px;
}