.Overflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width:100px; /* some width */
}

.Title {
    font-size: 24px;
    font-weight: 500;
    line-height: 28.13px;
    text-align: center;
}

.ListItemIcon {
    color: var(--color-gray-800);
    margin-right: 10px;
}

li.ListItem {
    cursor: pointer;
    width: 100%;
}