.Title {
    color: var(--color-gray-900);

    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
}

.Subtitle {
    color: var(--color-gray-500);

    font-size: 14px;
    font-weight: 400;
    line-height: 18px;

    margin-top: 5px;
}