.Root {
    max-width: 280px;
}

.Controls {
    display: flex;
    align-items: center;
    justify-content: center;

    column-gap: 20px;
    row-gap: 15px;
    width: 100%;
}

.StackedControls {
    flex-direction: column;
    flex-direction: column-reverse;
}

.Centred {
    text-align: center;
}