.Root {
  font-size: 14px;
  font-style: normal;
  color: var(--color-purple-700);
  font-weight: 500;
}

.Root:hover {
  color: var(--color-purple-800);
}

.Root:active {
  color: var(--color-purple-900);
}

@media only screen and (max-width: 680px) {
  .Root {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
  }
}
