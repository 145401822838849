.Frame {
    border: solid 10px black;
    border-radius: 36px;

    margin: auto;
    overflow: hidden;
    background: white;
    position: relative;
}
.Root {
    overflow: hidden;
    aspect-ratio: 9/19;
}

.Container {
    aspect-ratio: 9/19;
    transition: all .3s;
    transform-origin: 0 0;
}

.WithoutTransition {
    transition: none;
}

.Screen {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    height: 100%;
}

.Scroll {
    overflow-y: auto;
    padding: 0;
    width: 100%;
    height: 100%;
}

.BannerShadow {
    height: 105px;
}