.Root {
  max-width: 280px;
}

.Controls {
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 20px;
}

.VerticalActionButtons {
  width: 100%;
  flex-direction: column;
  gap: 15px;
}

.Centred {
  text-align: center;
}

.WiderDialog {
  max-width: 300px;
}

.WiderDialogTitle {
  margin-bottom: 5px;
}