.ShareBtn {
    height: 38px;
}

.Disabled {
    opacity: 0.5;
}

.Disabled:hover {
    background: none !important;
    cursor: initial;
}