.Date {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--color-gray-500);
  white-space: nowrap;
  align-self: end;
}
