.Root {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    overflow: hidden;
}

.Properties {
    overflow: hidden;
    width: 100%;
}

.Name,
.Title,
.Company {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Name {
    color: #222;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
}

.Title,
.Company {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
}

.Title {
    color: #222;
}

.Company {
    color: #888;
}