.Root {
  min-height: 53px;
}

.PlanName {
  color: var(--color-gray-900);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.PlanPrice {
  color: var(--color-gray-900);
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.PlanExpiresMessage {
  color: var(--color-others-alert-600);
}

.RowBorder {
  width: 100%;
  border-bottom: solid 1px var(--color-gray-200);
}

.BillingInterval {
  font-size: 14px;
}
