.Root {
  padding: 15px 20px 40px 20px;
}

.Item {
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }
}
