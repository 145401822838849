.Root {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.Avatar {
  text-transform: uppercase;
  border-radius: 19px;
  font-size: 21px;
  font-weight: 500;
}

.PostMessageInput {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
  padding: 0 4px 0 17px;
  border-radius: 19px;
  background-color: var(--color-gray-150);
  user-select: none;
  cursor: pointer;
}

.PostMessageInputText {
  flex: 1;
  color: var(--color-gray-400);
  height: 22px;
  display: flex;
  align-items: center;
}

.AttachButton {
  background-color: var(--color-gray-150);
  width: 38px;
  height: 38px;
  border-radius: 50%;
}

span.AttachTooltipContent {
  white-space: nowrap;
  margin-left: -35px;
}

.AttachIconButton:active {
  background: var(--color-gray-250);
}

.AttachIcon {
  color: var(--color-gray-700);
}
