.Root {
    display: flex;
    flex-direction: column;
    width: 360px;
    background: var(--secondary-color);
    padding-bottom: 40px;
    overflow: hidden;
    position: relative;
    border-radius: 25px;
}

.Header {
    height: 125px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background: var(--color-purple-400);
    position: relative;
    z-index: 1;
}

.CloseButton {
    position: absolute;
    top: 10px;
    right: 10px;
}

.HeaderIcon {
    border-radius: 50%;
    height: 174px;
    width: 174px;
    margin-bottom: -87px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--secondary-color);
}

.Icon {
    display: flex;
    font-size: 50px;
    line-height: 59px;
    margin-top: -59px;
}

.Body {
    position: relative;
    z-index: 2;
    background: var(--secondary-color);
    padding: 40px 30px;
}

.Actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}