.Root {
    width: 100%;
    height: 125px;
    padding: 30px 30px 10px;
    display: flex;
    justify-content: center;
    position: relative;
}

.Close {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 22px;
    right: 22px;
}

.Header {
    display: flex;
    justify-content: center;
}

span.EmailIcon {
    font-size: 120px;
}