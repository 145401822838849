.Root {
  color: white;
  border-radius: 30px;
  font-style: normal;
  transition: 200ms ease-in 0s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;

  --primary-color: #4359FF;
  --primary-hover-color: #4359FF;
  --primary-active-color: #2C3DC2;

  --primary-text-color: #4359FF;
  --primary-text-hover-color: #d6e9ff;
  --primary-text-active-color: #a2ceff;

  --secondary-color: #4359FF;
  --secondary-hover-color: #4359FF;
  --secondary-active-color: #2C3DC2;
  --secondary-disabled-color: #c4c4c4;

  --danger-color: #d80034;
  --danger-hover-color: #ffdfdf;
  --danger-active-color: #ffaaaa;

  --gray-color: black;
  --gray-hover-color: #f2f2f2;
  --gray-active-color: #c4c4c4;

  --gray-contained-color: #222222;
  --gray-contained-active-color: #222222;
  --gray-contained-background: #efefef;
  --gray-contained-active-background: #D9DEFF;
  --gray-contained-hover-background: #F2F4FF;
}

.gray-contained {
  color: var(--gray-contained-color);
  background: var(--gray-contained-background);
}
.gray-contained:not(:disabled):hover {
  background: var(--gray-contained-hover-background);
}
.gray-contained:not(:disabled):active {
  color: var(--gray-contained-active-color);
  background-color: var(--gray-contained-active-background);
}

:disabled {
  cursor: default;
}

.Small {
  padding: 5px 15px;
  letter-spacing: 0.25px;
  height: 32px;
  font-size: 12px;
  font-weight: 500;
}
.Medium {
  padding: 0px 22px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.4px;
  height: 40px;
}
.Large {
  padding: 0px 20px;
  border-radius: 90px;
  font-style: normal;
  font-size: 18px;
  letter-spacing: 0.0025em;
  height: 64px;
  font-weight: 500;
}

.primary-contained {
  background-color: var(--primary-color);
}
.primary-contained:not(:disabled):hover {
  background: var(--primary-hover-color);
}
.primary-contained:not(:disabled):active {
  background: var(--primary-active-color);
}

.secondary-contained {
  background: transparent;
  border: solid 2px #4359FF;
  color: #4359FF;
}
.secondary-contained:not(:disabled):hover {
  border-color: var(--primary-hover-color);
  background: var(--primary-hover-color);
  color: white;
}
.secondary-contained:not(:disabled):active {
  border-color: var(--primary-active-color);
  background: var(--primary-active-color);
  color: white;
}
.secondary-contained:disabled {
  border-color: var(--secondary-disabled-color);
  background: transparent;
  color: var(--secondary-disabled-color);
}

.primary-text {
  color: var(--primary-text-color);
  background-color: white;
}
.primary-text:not(:disabled):hover {
  background: var(--primary-text-hover-color);
}
.primary-text:not(:disabled):active {
  background: var(--primary-text-active-color);
}

.danger-text {
  color: var(--danger-color);
  background-color: white;
}
.danger-text:not(:disabled):hover {
  background: var(--danger-hover-color);
}
.danger-text:not(:disabled):active {
  background: var(--danger-active-color);
}

.gray-text {
  color: var(--gray-color);
  font-size: 14px;
  font-weight: 400;
}
.gray-text:not(:disabled):hover {
  background: var(--gray-hover-color);
  font-size: 14px;
  font-weight: 400;
}
.gray-text:not(:disabled):active {
  background: var(--gray-active-color);
  font-size: 14px;
  font-weight: 400;
}

.primary-contained:disabled,
.primary-text:disabled,
.danger-text:disabled {
  background: #c4c4c4;
  color: white;
  border: none;
}

.Loading,
.Loading:disabled,
.Loading:hover {
  color: transparent;
}

.FullWidth {
  width: 100%;
  padding: 0px;
}

.Spinner {
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
}
.Spinner div {
  /*transform-origin: 15px 15px;*/
  margin-top: 10px;
  animation: Spinner 1.2s linear infinite;
}
.Spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 4px;
  left: 9px;
  width: 2px;
  height: 5px;
  border-radius: 20%;
  background: #fff;
}
.Spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.Spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.Spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.Spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.Spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.Spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.Spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.Spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.Spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.Spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.Spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.Spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes Spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.StartIcon {
  margin-top: 2px;
  margin-right: 5px;
  display: inherit;
}

@media only screen and (max-width: 680px) {
  .Large {
    padding: 16px 35px;
    line-height: 24px;
    height: 54px;
    border-radius: 22px;
  }
}
