.Root {
    background-color: var(--color-purple-100);
    border-radius: 25px;
    padding: 15px;
    margin: 10px 15px;
}

.Header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.Icon {
    font-size: 24px;
}

.Title {
    margin: 10px 0;
    font-size: 14px;
    line-height: 18px;
    color: var(--color-gray-900)
}
.Forward {
    font-size: 14px;
    line-height: 18px;
    color: var(--color-purple-700);
    font-weight: 500;
    display: inline-block;
    align-items: center;
    gap: 5px
}


.Forward > svg {
    margin-left: 5px
}