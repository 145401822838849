.Root {
  cursor: pointer;
  padding: 0 15px;
}

.Root:not(.Disabled):hover {
  background: var(--color-purple-100);
  border-radius: 16px;
}

.Root.Disabled {
  opacity: 0.5;
  cursor: default;
}
