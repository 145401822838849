.Root {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  justify-content: center;
  height: 100%;
}

.Title {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: var(--color-gray-900);
  margin-bottom: 20px;
}

.Message {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-gray-900);
  text-align: center;
}