.Root {
  position: relative;
  width: 450px;
  height: 466px;
  background: linear-gradient(to bottom, #06162d 0%, #9496b1 100%);
  border-radius: 25px;
}

.CloseButton {
  position: absolute;
  top: 18px;
  right: 22px;
  color: white;
  cursor: pointer;
}

.HeaderCnt {
  padding: 31px 40px 0 40px;
}

.Header {
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  color: var(--secondary-color);
}

.HeaderDescription {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: var(--secondary-color);
  margin-top: 15px;
}

.DetailsCnt {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 308px;
  border-radius: 25px;
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Bullets {
  padding: 32px 40px 0 40px;
}

.Bullet {
  display: flex;
  align-items: flex-start;
  padding: 12px 45px 12px 0;
}

.BulletTitle {
  max-width: 290px;
  margin-left: 15px;
  font-size: 14px;
  font-weight: 400;
}

.ActionCnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px 0;
  box-shadow: 0 -4px 8px 0 #0000000a;
}

.ActionButton {
  width: 200px;
}
