.Message {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: var(--color-gray-500);
    margin: 10px 20px;
}

.Link {
    display: inline-block;
    all: unset;

    cursor: pointer;
    color: var(--color-purple-700)
}