.Disabled {
  opacity: 0.5;
  background: none !important;
  cursor: initial;
}

.ItemIcon {
  margin-right: 10px;
  font-size: 20px;
  color: var(--color-gray-800);
}

.Menu {
  margin-top: 5px;
}

span.DeleteIcon {
  margin-top: -30px;
  font-size: 120px;
  margin-bottom: 10px;
}
