.Root {
  width: 300px;
}

.DialogRoot > span > button:hover,
.DialogRoot > span > button:active {
  background: transparent;
}

.IconContainer {
  background: transparent;
  display: flex;
  justify-content: center;
  margin-top: -40px;
}

div.Container {
  background: var(--secondary-color);
  padding: 0;
}

div.Content {
  margin: 0;
}

div.Actions {
  margin: 30px 0 0;
}

.Message {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  color: var(--color-gray-900);
}
