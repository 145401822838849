.Main {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 160px);
  padding: 0 1rem;
}

.Form {
  display: flex;
  flex-direction: column;
  width: 426px;
  margin-bottom: 2rem;
  padding-top: 1rem;
  height: 530px;
}

.RememberMe {
  display: flex;
  justify-content: space-between;

  & label {
    color: var(--color-gray-900);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-left: 0;
  }
}

.ForgotPasswordLink {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 680px) {
  .Form {
    padding-top: 0;
  }
}
