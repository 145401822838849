.Root {
    width: 300px;
}

.DialogRoot > span > button:hover,
.DialogRoot > span > button:active {
    background: transparent;
}

.IconContainer {
    background: transparent;
    display: flex;
    justify-content: center;
}



div.Container {
    background: var(--secondary-color);
    padding: 0;
}

div.Content {
    margin: 0;
}

.Title {
    color: var(--color-gray-900);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 15px;
}

div.Actions {
    margin: 40px 0 0;
}

.Message {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    color: var(--color-gray-900);
}