.Root {
  width: 520px;
  margin: 0 auto;
  border-radius: 36px;
  background: white;
}

.Page {
  position: relative;
  min-height: calc(100vh - 128px);
  min-width: 340px;
  margin: 0 auto;
  border-radius: 36px;
  overflow: hidden;
  height: 100%;
}

.ButtonWrapper {
  display: flex;
  justify-content: flex-end;
}