.Line {
    background: var(--color-gray-250);
    height: 1px;
    width: 100%;
}

div.Indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;

    & span {
        margin-right: 10px;
    }
}