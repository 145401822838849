.Root {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    line-height: normal;
}

.Scrollable {
    overflow-x: visible;
    overflow-y: auto;
    flex-grow: 2;

    background-image: linear-gradient(to top, white, white),
    linear-gradient(to top, white, white),
    linear-gradient(to top, rgba(0, 0, 0, 0.07), rgba(255, 255, 255, 0)),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.07), rgba(255, 255, 255, 0));

    background-position: bottom center, top center, bottom center, top center;
    background-color: white;
    background-repeat: no-repeat;
    background-size: 100% 6px, 100% 6px, 100% 6px, 100% 6px;
    background-attachment: local, local, scroll, scroll;
    -webkit-overflow-scrolling: touch
}