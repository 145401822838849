.Root {
    padding: 10px;
    min-width: 200px;
}
.Menu {
    border-radius: 16px;
    height: 38px;
    width: 100%;
    padding: 7px 12px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;

    transition: background-color 0.3s ease;
}

.Item {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    text-align: left;
    background: transparent;

    font-size: 14px;
    line-height: 17px;
    color: #222222;
    white-space: nowrap;
}

.Menu:hover {
    background-color: var(--color-purple-100);
}

.Menu:active {
    background-color: var(--color-purple-200);
}