.Icon {
    margin-right: 10px;
    min-width: 21px;
    vertical-align: middle;
    display: inline-flex;
}

.Divider {
    border: solid 1px var(--color-gray-200);
    margin: 10px 10px;
}
