.Root {
  width: 360px;
  background: white;
  border-radius: 25px;
}

.IconContainer > div > span {
  bottom: 125px;
}

.Content {
  text-align: center;
}

.Clear {
  background: white;
  box-shadow: none;
  padding: 0;
}