.Root {
    margin-top: 13px;
    margin-bottom: 20px;
    display: flex;
    height: 40px;
    align-items: center;
}

.Meta {
    display: flex;
    flex-direction: column;
}

.Icon {
    font-size: 29px;
    width: 29px;
    height: 29px;
    margin-right: 10px;
}

.Title {
    color: var(--main-color);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.Name {
    color: var(--color-gray-500);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}