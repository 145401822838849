@keyframes slideIn {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

.Root {
    animation: slideIn 0.4s ease;
    overflow: hidden;
}

.Controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 17px 5px 17px;
}

.BackIcon {
    height: 32px;
    width: 32px;
}