.Root {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 45px;
    padding: 0 5px;
    border-bottom: 1px solid transparent;
}

.Link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 16px;
    line-height: 21px;
    margin: 0 15px;

    border: 2px solid transparent;
    transition: border-bottom-color 0.2s ease;
}

.LinkDisabled {
    opacity: 0.5;
}

.LinkDisabled:hover {
    cursor: initial;
}

.Active {
    border-bottom-color: var(--color-purple-600-primary);
}