ul.Menu {
  min-width: auto;
  padding: 0;

  .Item {
    margin-bottom: 5px;
    color: var(--color-gray-900);

    &.Active,
    &.Active:hover {
      background-color: var(--color-purple-200);
      pointer-events: none;
      cursor: default;
    }
  }
}

.Icon {
  margin-right: 10px;
  color: var(--color-gray-800);
}
