@keyframes Animation {
    0% {
        transform: translateY(-10px) scaleY(0.9);
        opacity: 0;
    }
    100% {
        transform: none;
        opacity: 1
    }
}

@keyframes CenterAnimation {
    0% {
        transform: translateY(-10px)  translateX(-50%) scaleY(0.9);
        opacity: 0;
    }
    100% {
        transform: translateX(-50%);
        opacity: 1
    }
}

.Root {
    position: relative;
}

.Target {
    cursor: pointer;
}

.Content {
    min-width: 100%;
    position: absolute;
    top: 100%;
    background: #FFFFFF;
    border: 1px solid #F8F8F8;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    z-index: 999;

    animation: Animation 0.3s ease;
    transform-origin: top;
    -webkit-font-smoothing: antialiased;
}

.Content.LeftPosition {
    left: 0;
}
.Content.RightPosition {
    right: 0;
}
.Content.CenterPosition {
    left: 50%;
    animation: CenterAnimation 0.3s ease;
    transform: translateX(-50%);
}