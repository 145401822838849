.Root {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 0px 20px;
    height: 100%;
}

.Title {
    font-size: 16px;
    color: var(--color-gray-600);
}

.Icon {
    margin-right: 8px;
}

.UndoRedo {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}