.IconContainer {
  width: 20px;
  height: 20px;
}

.Icon {
  font-size: 20px;
  vertical-align: middle;
}

.ParentContainer {
  position: relative;
}

.Flex {
  display: flex;
  margin-right: -2px;
}
