.Root {
  padding: 20px 10px 65px 10px;
  column-gap: 20px;
  row-gap: 8px;
  display: grid;
  grid-template-columns: 50%;
  overflow-y: auto;
  max-height: 329px;
  grid-template-areas: 'item item';
}

.Item {
  box-shadow: 0px 4px 16px 0px #00000026;
  padding: 8px;
  border-radius: 48px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  justify-content: space-between;
}
.Item.Selected {
  background: #e5f2ff;
}
.Item .Email {
  font-size: 12px;
  margin-left: 10px;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.Item.Disabled,
.Item.Disabled .Email {
  cursor: default;
}
.Item.Disabled .Email {
  color: #c1c1c1;
}

.ItemLeft {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.Item .Grid {
  display: grid;
  align-items: center;
  grid-template-columns: 30px 10fr 1fr;
  align-content: center;
}

.Opacity {
  opacity: 0.5;
}
