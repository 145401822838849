.Root {
  position: sticky;
  top: 0;
  left: 0;
  height: 60px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  border-radius: 25px 25px 0 0;
  overflow: hidden;
  padding: 10px 10px 0;
  z-index: 999;
}

.RootBoxShadow {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}

.FolderTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}

.Icon {
  font-size: 26px;
  margin-right: 2px;
}

.BackButton {
  position: absolute;
}

.BackButton > svg > path {
  fill: #222222;
}

.CloseButton {
  position: absolute;
  right: 10px;
}

.StickyHeaderText {
  height: 60px;
  align-items: center;
}

.StickyHeaderText > span {
  font-size: 18px;
  transition: 0.3s;
}

.ModalCloseIcon > svg {
  width: 16px;
  height: 16px;
}
