.Field {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  position: relative;
  max-width: 610px;
}
.Label {
  display: block;
  text-align: left;

  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 8px;
}

.InputText {
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.15px;
  border: none;
  border-bottom: 1px solid var(--color-purple-600-primary);
  width: 100%;
  padding-bottom: 8px;

  &:active, &:focus {
    border-bottom: 2px solid var(--color-purple-600-primary);
  }
}

.Footer {
  display: flex;
  justify-content: flex-end;
}

.Cover {
  width: 100%;
  object-fit: cover;
  margin-top: 5px;
}

.Wrapper {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid var(--color-purple-600-primary);
  cursor: text;
  display: flex;
  align-items: center;
  max-width: calc(100% - 45px);
  padding-bottom: 5px;
}
.Url {
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 0.15px;
  color: var(--color-purple-600-primary);
  display: inline-block;
  padding: 0px 2px 0px 0px;
  word-break: break-all;
}
.UrlReadOnly {
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 0.15px;
  color: #6F7175;
  display: inline-block;
  padding: 0px 2px 0px 0px;
  word-break: break-all;
}

.ErrorMessage {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.15px;
  color: #E54432;
  text-align: left;
  margin-top: 5px;
}

.PdfIcon {
  margin-right: 12px;
}

.Loading {
  position: absolute;
  right: 0px;
  top: 0px;
  background: white;
  width: 31px;
}

.FooterControl {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}