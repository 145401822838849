.Root {
  list-style-type: none;
  padding: 6px 15px;
  cursor: pointer;
  background-color: white;
  height: 46px;
  display: flex;
  align-items: center;
  border-radius: 19px;
  transition: background-color 0.3s ease;
}

.Root:last-child {
  border-bottom: none;
}

.Root:hover {
  background-color: var(--color-purple-100);
}

.Selected {
  background: var(--color-purple-200)
}