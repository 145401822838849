.Main {
  background: white;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 160px);
}

.Form {
  display: flex;
  flex-direction: column;
  width: 426px;
  margin-bottom: 2rem;
  padding-top: 1rem;
  height: 530px;
}