.Root {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-right: 82px;
}

.Message {
  max-width: max(0px, calc((100% - 245px)*999));
  overflow: hidden;
  max-height: 20px;
  overflow-wrap: anywhere;
  font-size: 14px;
}
