.Root {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--modal-z-index);
  background-color: rgba(0,0,0,0.5);
  overflow-y: auto;
}

.Content {
  --node-content-padding: 12px;
  border-radius: 25px;
  box-shadow: var(--key-shadow);
  margin: auto;
  background-color: white;
  transition: max-height 0.9s;
}
.Content.Small {
  width: 650px;
}
.Content.Large {
  width: 800px;
}
.Content.Basic {
  width: 480px;
  margin-top: calc(20vh + 100px);
}

.Content.SmallPopUp {
  width: 360px;
}

.Content.VeryLarge{
  width: 900px;
}

.Content.Basic .Header {
  height: 60px;
  padding: 35px 40px 10px 40px;
}
.Header {
  display: flex;
  height: 60px;
  justify-content: space-between;
  padding: 20px 20px 0px 20px;
  align-items: center;
  border-radius: 23px 23px 0px 0px;
}

.Body {
  display: block;
  flex-grow: 1;
  width: 100%;
  text-align: left;
  padding: 25px 30px;
  position: relative;
}

.Content.Basic .Body {
  padding: 25px 40px;
}

.Content.VeryLarge .Body{
  padding: 0;
}

.Option {
  font-size: 14px;
  font-weight: 400;
  color: black;
  display: flex;
}
.Option svg {
  margin-right: 8px;
}


.HeaderLeft {
  display: flex;
  align-items: center;
  grid-gap: 5px;
}