.Root {
  max-height: calc(100% - 120px);
  /*
  display: flex;
  align-items: center;
  flex-direction: column;
  */

  margin: 0 auto;
  padding: 0 20px;
}

.Image {
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
}