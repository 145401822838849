.Centred {
    text-align: center;
    max-width: 280px;
}

.Button {
    margin: 0 15px;
}

.NonDefault {
    max-width: 281px;
}

span.DeleteIcon {
    margin-top: -30px;
    font-size: 120px;
    margin-bottom: 10px;
}