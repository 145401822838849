.Title,
.P {
    font-family: Roboto, Open Sans, sans-serif;
    color: var(--color-gray-900);
    text-align: center;
}

.Title {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 20px;
}

.P {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}

.Actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
}

.Actions button {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}