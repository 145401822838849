.AdditionalFields {
    margin: 10px 0 15px;
}

.RequiredLabel {
    display: flex;
    justify-content: flex-end;

    font-size: 14px;
    font-weight: 400;
    color: var(--color-gray-500);
    margin-top: 15px;
    margin-bottom: 10px;
}