.ItemIcon {
  display: inline-flex;
  margin-right: 10px;
  color: var(--color-gray-800);
}

span.Icon {
  font-size: 120px;
  margin-bottom: 10px;
}

div.DeleteContentPopUp {
  margin-top: -30px;
}