.Root {
  position: absolute;
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  color: var(--color-gray-600);
  flex-flow: column;
}

.IconContainer {
  padding-bottom: 15px;
}
