.Root  {
  background-color: var(--node-background);
  border: solid 1px;
  border-color: var(--node-background);
  transition: border-color,background-color .25s ease-in-out;
  height: 100%;
  border-radius: 19px;
  overflow: hidden;
}

.ImageWrapper {
  height: 131px;
  position: relative;
}

.Content {
  height: 67px;
  padding: var(--node-padding);
  display: flex;
  column-gap: 7px;
  align-items: center;
}

.Root.OneLine .Content {
  align-items: center;
}

.Content .PdfIcon {
  min-width: 26px;
  color: var(--color-others-alert);
}

.Content .Title {
  font-size: var(--node-title-fontsize);
  line-height: var(--node-title-lineheight);
  font-weight: 500;
  color: var(--node-title-color);
  opacity: var(--node-title-opacity);

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}