
.CoverGray,
.CoverWhite {
  width: 50px;
  height: 50px;
  border-radius: 19px;
  overflow: hidden;
  position: relative;  
  display: flex;
  align-items: center;
  justify-content: center;
}

.CoverGray {
  background: #EFEFEF;
}
.CoverWhite {
  background: white;
}

.Cover {
  object-fit: cover;
  width: 50px;
  height: 50px;
}

.Icon {
  position: absolute;
  padding-top: 5px;
}