.FolderLoadingSpinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: calc(100vh - 290px);
}

.EmptyContent {
  display: flex;
  align-items: center;
  height: 65vh;
}

.Modal {
  border-radius: 25px;
  height: calc(100% - 60px);
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 30px auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.Modal::-webkit-scrollbar {
  display: none;
}

.LayoutContainerSmall {
  min-height: calc(100vh - 296px);
}

.BoardContainer {
  margin-top: 30px;
  padding-bottom: 30px;
}

.BoardContainerWithFooter {
  padding-bottom: 138px;
}

.EmptyBoardContainer {
  display: flex;
  align-items: center;
  min-height: calc(100vh - 283px);
}

.EmptyBoardContainerJoin {
  min-height: calc(100vh - 282px);
}

.FolderTitle {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 24px;
  color: var(--color-gray-900);
  line-height: 24px;
  font-weight: 500;
  position: sticky;
  top: -1px;
  z-index: 99;
  background-color: var(--secondary-color);
  transition: 0.3s;
}

.FolderTitle > span {
  transition: 0.3s;
}
