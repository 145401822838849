.Root {
  width: 360px;
}

div.Container {
  background: var(--secondary-color);
  padding: 20px 30px 40px;
}

.Title {
  color: var(--color-gray-900);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

div.Content {
  margin: 0;
  text-align: center;
}

div.Actions {
  margin: 40px 0 0;
}

.Message {
  padding-top: 15px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: var(--color-gray-900);
}

span.CrownIcon {
  font-size: 120px;
  margin-top: -50px;
}