.Root {
    margin-bottom: 35px;
}

.KingBadge {
    cursor: pointer;
    font-size: 22px;
}

.SectionTitle {
    display: flex;
    gap: 5px;

    margin-bottom: 5px;

    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
}

.SectionSubTitle {
    display: block;

    margin-bottom: 25px;

    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #888888;
}

.InputLabel {
    display: flex;
    margin: 20px 0;
    cursor: pointer;
    color: black;

    & > div {
        width: 100%;
    }
}

.BlurInput {
    background: rgb(239, 239, 239);
    border-color: rgb(239, 239, 239);
    color: rgb(153, 153, 153);
}

.FullWidth {
    width: 100%;
}

.RadioInput {
    width: calc(100% - 34px);
}