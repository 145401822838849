.Opacity {
  opacity: 0.5;
}

.RightAlignedButton {
  margin-left: auto;
}

.isFreeUserMenu {
  padding: 0 20px;
}