.UpgradeButton {
  display: flex;
  height: 46px;
  align-items: center;
  justify-content: space-between;
  border-radius: 0;
}

.IconContainer {
  display: flex;
  gap: 3px;
  align-items: center;
}
