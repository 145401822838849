.Root {
	box-sizing: border-box;
	border: 0 none transparent;
	border-radius: 3px;
	resize: none;
	overflow: hidden;
	height: auto;
	padding: 0;
	width: 100%;
	outline: 0 solid transparent;
}