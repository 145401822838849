.Root {
  height: 100%;
  background-color: var(--node-background);
  transition: background-color .25s ease-in-out;
  position: relative;
}

.Image{
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.Title {
  position: absolute;
  width: 100%;
  bottom: 0px;
  height: 83px;
  padding: 15px;
  
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 51.33%, rgba(0, 0, 0, 0.9) 100%);

  display: flex;
  align-items: flex-end;
}

.Title span {
  color: white;
  font-size: var(--node-text-fontsize);
  line-height: var(--node-text-lineheight);
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}