@keyframes FadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.CreatePageBanner {
    height: 85px;
    width: 100%;
    min-width: 300px;
    max-width: 700px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 15px 10px 15px 30px;
    border-radius: 34px;
    animation: FadeIn 0.3s ease;
}

.CreatePageBanner:not(.UnbrandedPages) {
    background: var(--color-purple-100);
}

.CreatePageBanner.UnbrandedPages {
    background: var(--color-gray-200);
}

.CreatePageBanner.UnbrandedPages:hover .DeleteIcon path {
    fill: var(--color-gray-800);
}

.CreatePageBanner.UnbrandedPages button.DeleteButton:not(:disabled):hover {
    background-color: var(--color-gray-300);
}

.CreatePageBanner.UnbrandedPages .DeleteIcon {
    color: var(--color-gray-600);
}

.DeleteIcon {
    font-size: 20px;
    vertical-align: middle;
}

.Message {
    font-size: 14px;
    color: var(--color-gray-900);
    line-height: 20px;
    margin-top: 5px;
}

span.Logo {
    height: 16px;
}

span.Logo > svg {
    height: 16px;
    display: block;
}