.Root {
    width: 100%;
  }
  
  .Icon {
    vertical-align: middle;
    margin-right: 2px;
  }
  
  .CopyText {
    margin-left: 5px;
    vertical-align: middle;
  }
  
  .Title {
    color: white;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    opacity: 0.8;
  
    -webkit-line-clamp: 1;
    overflow: hidden;
    overflow-wrap: anywhere;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  
    padding-right: 30px;
  }
  
  .MenuActions {
    margin-top: 5px;
  }
  
  .Header {
    grid-template-columns: 1fr 119px minmax(300px, 1fr);
  }
  
  .NextIcon,
  .PrevIcon {
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
  
    border-radius: 19px;
  }
  .PrevIcon {
    left: 20px;
  }
  .NextIcon {
    right: 20px;
  }
  
  .Root .PrevIcon:disabled,
  .Root .NextIcon:disabled {
    opacity: 0.5;
  }
  .Root .PrevIcon:not(:disabled),
  .Root .NextIcon:not(:disabled) {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .Root .PrevIcon:not(:disabled):hover,
  .Root .NextIcon:not(:disabled):hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
  .Root .PrevIcon:not(:disabled):active,
  .Root .NextIcon:not(:disabled):active {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .SharedFolderModeSaveButtonContainer {
    position: fixed;
    bottom: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 10;
  }
  
  .SaveButton {
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
    border-radius: 19px;
  }
  
  div.Banner {
    position: relative;
    z-index: 1;
    width: 540px;
    margin: 0 auto -50px;
    border-radius: 20px 20px 0 0;
  
    & ~ div > div:first-child {
      padding-top: 80px;
    }
  }
  
  
  div.DisabledBanner {
    pointer-events: none;
  }