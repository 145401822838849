.Icon {
  vertical-align: middle;
}

.Offset {
  margin-top: -5px;
  white-space: nowrap;
}

.ViewOnlyButton {
  padding: 0 12px;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-gray-500);
}