.Root {
  margin-top: 20px;
  text-align: center;
}
.Message {
  font-size: 16px;
  font-style: normal;
  margin-right: 5px;
  font-weight: 400;
  line-height: 22px;
  color: var(--color-gray-900);
}

a.Link {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: var(--color-purple-700);
}

@media only screen and (max-width: 680px) {
  .Message {
    font-size: 16px;
    color: var(--color-gray-900);
    line-height: 22px;
    font-weight: 400;
  }
}
