
.Root  {
  background-color: var(--node-background);
  height: 100%;
  transition: background-color .25s ease-in-out;
}

.Title {
  font-size: var(--node-title-fontsize);
  line-height: var(--node-title-lineheight);
  font-weight: 500;
  overflow-wrap: anywhere;
  color: var(--node-title-color);
  opacity: var(--node-title-opacity);

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.Text {
  font-size: var(--node-text-fontsize);
  line-height: var(--node-text-lineheight);
  overflow-wrap: anywhere;
  color: var(--node-text-color);
  opacity: var(--node-title-opacity);

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.TitleOrText {
  display: flex;
  height: 100%;
  align-items: center;
}

.TitleOrText .Title,
.TitleOrText .Text {
  -webkit-line-clamp: 3;
  overflow: hidden;
}


.TitleWithOneLineText .Title,
.TitleWithTwoLinesText .Title {
  -webkit-line-clamp: 2;
  margin-bottom: 5px;
}

.TitleWithOneLineText .Text {
  -webkit-line-clamp: 1;
}
.TitleWithTwoLinesText .Text {
  -webkit-line-clamp: 2;
}