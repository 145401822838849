.Root {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: var(--color-gray-200);
    border-radius: 15px;
    cursor: pointer;
    padding: 10px;
    transition: background-color .25s ease-in-out;
}

.Root:hover {
    background-color: var(--color-purple-200);
}

.Root:active {
    background-color: var(--color-purple-300);
}

.Label {
    font-size: 14px;
    margin-bottom: 8px;
    text-align: center;
    color: var(--color-gray-900);
}

.IconWrapper {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    background: rgb(0, 0, 0);
    border-radius: 14px;
}

.Icon {
    font-size: 23px;
}