.Root {
  min-height: 360px;
  width: 590px;
}
.Root.WithTitle {
  min-height: 400px;
  width: 590px;
}

.UploadProgressBarWrapper {
  height: 360px;

  display: flex;
  align-items: center;
  justify-content: center;
}