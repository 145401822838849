.Root {
  width: 100%;
}

.UploadingRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 15px;
}

.UploadingRow span {
  font-size: 16px;

  color: var(--color-gray-600);
}

.Background {
  height: 4px;
  width: 100%;
  background-color: var(--color-gray-300);
  border-radius: 30px;
}

.Filler {
  height: 100%;
  border-radius: inherit;
  transition: width 1s;
}

.Blue .Filler {
  background-color: var(--color-purple-500);
}
.Red .Filler {
  background-color: var(--color-others-alert);
}