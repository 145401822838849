.Connected {
    display: flex;
    width: 100%;
    padding: 15px;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    border-radius: 14px;
    border: 1px solid var(--color-gray-250);
}

.ConnectedIndicatorWrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

span.Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    width: 38px;
    font-size: 28px;
    background: #E0E9FF;
    color: #002987;
    border-radius: 10px;
}

.IndicatorWrap {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    flex-direction: column;
    color: #888888;
}

.PayPal {
    color: var(--color-gray-900);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
}

.Indicator {
    color: #299F00;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

.IndicatorWithError {
    color: var(--color-others-alert-600);
    font-weight: 500;
}

.ButtonOverrider button {
    color: #222;
    height: 54px;
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;

    background: #DFE5FF;
}

.ButtonOverrider button:hover {
    background: #dce1f3;
}

.ButtonOverrider button:active {
    background: #b3c1fd;
}

button > .PaypalIcon {
    color: #0A217D;
}
 button:hover > .PaypalIcon {
    color: #0A217D;
}
 button:active > .PaypalIcon {
    color: #04196F;
}

.PaypalIcon {
    color: #142C8E;
}

.OnlyOwn {
    font-size: 14px;
    line-height: 18px;
    color: #222222;
    background-color: var(--color-purple-100);
    padding: 15px;
    border-radius: 14px;
}

span.InfoIcon {
    vertical-align: center;
    color: #5DABFF;
    margin-right: 8px;
    margin-top: -2px;
}

span.SpinnerIcon {
    margin-left: 8px;
    color: #002987;
}

.DisconnectSection {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.ErrorMessage {
    color: var(--color-others-alert-600);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.154px;
    padding-left: 10px;
    padding-right: 10px;

    display: flex;
    align-items: flex-start;
    column-gap: 5px;

    svg {
        margin-top: 1px;
    }
}

.Menu {
    margin-top: -12px;
    margin-right: -5px;
    color: var(--color-gray-700);
}

button.UnLinkButton {
    font-size: 14px;
    line-height: 18px;
    color: var(--color-others-alert-600);
}

button.UnLinkButton:hover {
    background: var(--color-others-alert-100);
}

.UnLinkIcon {
    margin-right: 7px;
}

.DisconnectSpinner {
    margin-top: -20px;
}

.ErrorState {
    border: 2px solid var(--color-others-alert-600);
    background: var(--color-others-alert-100);
}