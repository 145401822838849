.Root {
    position: relative;
}
.ResizableIcon {
    cursor: col-resize;
    right: 0;
    top: 0;
    margin-right: -14px;
    width: 14px;
    height: 100%;
    line-height: 14px;
    position: absolute;
    z-index: 1;
    user-select: none;
    display: block;
    opacity: 0;
}
.ResizableIcon:hover {
    opacity: 1;
    border-left: solid 1px var(--color-purple-400);
}

.TooltipTarget {
    height: 100%;
    width: 100%;
}