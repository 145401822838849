.Root {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    height: 100vh;
}

.Main {
    height: 100%;
    flex: 1;
    display: flex;
}

.Header {
    border-bottom: solid 1px var(--color-gray-200);
    padding: 16px 8px;
    max-height: 70px;
}

.TitleContainer {
    display: flex;
    align-items: center;
    color: var(--color-gray-800);
}

.Title {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    margin-left: 10px;
    color: var(--color-gray-900);
}


.SideBar {
    padding: 25px 15px 14px;
    min-width: auto;
    border-right: solid 1px var(--color-gray-200);
    display: flex;
    flex-direction: column;
}

.SideBarFooter {
    margin-top: auto;

    & > a {
        padding: 0 12px;
        border-radius: 16px;
        color: var(--color-gray-900);
    }
}

.Container {
    padding: 30px;
    width: 100%;
    position: relative;
}

.AppVersion{
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 14px;
    color: var(--color-gray-400);
}

