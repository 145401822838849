.Root  {
  background-color: var(--node-background);
  height: 100%;
  transition: background-color .25s ease-in-out;
}

.Title {
  font-size: var(--node-title-fontsize);
  line-height: var(--node-title-lineheight);
  font-weight: 500;
  overflow-wrap: anywhere;
  color: var(--node-title-color);
  opacity: var(--node-title-opacity);

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.Text {
  font-size: var(--node-text-fontsize) ;
  line-height: var(--node-text-lineheight);
  overflow-wrap: anywhere;
  color: var(--node-text-color);
  opacity: var(--node-title-opacity);

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.Favicon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.FirstColumn {
  width: 25px;
  min-width: 25px;
  display: inline-flex
}

.Wrapper {
  max-width: 100%;
  display: flex;
  column-gap: 10px;
  align-items: center;
}
.WithTitle .Title {
  margin-bottom: 5px;
}
.TitleWithTwoLines .Text {
  -webkit-line-clamp: 1;
}

.TitleWithText.Wrapper {
  align-items: flex-start;
}